import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import { UIButton } from '../../components/shared/UIButton.styles';

const RegWorkflowButtons = (props: any): JSX.Element => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(510));
  return (
    <Box
      sx={{
        display: 'flex',
        gap: '24px',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
      }}
    >
      {props?.buttonItems?.map((buttonItem: any, buttonItemIndex: number) => {
        return (
          <UIButton
            className="btn"
            variant={buttonItem?.fields?.buttonVariant?.value}
            onClick={(event) =>
              props.handleClick(
                event,
                buttonItem.fields?.link.value.href,
                buttonItemIndex === 0 ? 'back' : 'next'
              )
            }
            key={buttonItemIndex}
            fullWidth={isMobile}
            disabled={buttonItemIndex === 1 ? !props?.enableNext : false}
            sx={{
              padding: '5px',
              width: isMobile ? '100%' : '212px',
              order:
                (buttonItem.fields?.link?.value?.text).toLowerCase() === 'back' && isMobile ? 1 : 0,
            }}
          >
            {buttonItem.fields?.link.value.text}
          </UIButton>
        );
      })}
    </Box>
  );
};

export default RegWorkflowButtons;
