import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import {
  NextImage,
  RichText,
  Text,
  isEditorActive,
  useSitecoreContext,
} from '@sitecore-jss/sitecore-jss-nextjs';
import SearchForm from 'components/HeroAndSearch/SearchForm';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store/store';
import {
  StBannerWrapper,
  StBannerContainer,
  StBannerContent,
  StRichTextGridItem,
} from './HCPHeroAndSearch.styles';
import { setContext } from '../../../lib/utils/coveo/headlessContext';
const HCPHeroAndSearch: React.FC<any> = (props) => {
  const theme = useTheme();
  const isBiggerDesktop = useMediaQuery(theme.breakpoints.up('xl'));
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const isTablet = useMediaQuery(theme.breakpoints.down('lg'));
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const userProfile = useSelector((state: RootState) => state.crProfile);
  const profileDetails = userProfile?.profileData?.basicDetails;
  const { sitecoreContext } = useSitecoreContext();
  useEffect(() => {
    if (isEditorActive() || sitecoreContext?.pageState === 'preview') {
      return;
    }
    const userInterests = userProfile?.profileData.conditions?.map(
      (item: any) => item.conditionValue
    );
    if (profileDetails) {
      setContext({
        gender: profileDetails?.gender,
        dateOfBirth: profileDetails?.dob,
        interests: userInterests ? userInterests : [],
      });
    } else {
      setContext({
        gender: '',
        dateOfBirth: '',
        interests: '',
      });
    }
  }, [profileDetails?.dob, profileDetails?.gender, userProfile?.profileData.conditions]);

  if (!props?.fields) return <></>;

  return (
    <StBannerWrapper id="hero-and-search">
      <NextImage
        priority={true} // When true, the image will be considered high priority and preload
        field={
          isDesktop
            ? props?.fields?.desktopBGImage
            : isTablet
            ? props?.fields?.tabletBGImage
            : isMobile
            ? props?.fields?.mobileBGImage
            : props?.fields?.desktopBGImage
        }
        style={{
          zIndex: 0,
          background: `linear-gradient(73.33deg, #250056 -16.55%, #0076AE 73.37%)`,
        }}
        layout="fill"
        objectFit={isBiggerDesktop ? 'fill' : 'cover'}
        objectPosition="center"
      ></NextImage>

      <StBannerContainer id="hero-and-search-content" container>
        <Stack>
          <StBannerContent item xs={12} sm={12} md={12} lg={12}>
            <Grid
              item
              sx={{
                [theme.breakpoints.up('sm')]: {
                  maxWidth: '450px',
                },
              }}
            >
              <Typography
                gutterBottom
                variant="h1"
                data-testid="hero-and-search-title"
                sx={{
                  fontSize: '32px',
                  marginBottom: '8px',
                  lineHeight: '45px',
                }}
              >
                <Text field={props?.fields?.heading}></Text>
              </Typography>
            </Grid>
            <StRichTextGridItem item>
              <RichText field={props?.fields?.content} data-testid="hero-and-search-content" />
            </StRichTextGridItem>
          </StBannerContent>

          <SearchForm {...props} isHCP={true} />
        </Stack>
      </StBannerContainer>
    </StBannerWrapper>
  );
};

export default HCPHeroAndSearch;
