import React from 'react';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import { useTheme } from '@mui/material/styles';

import Logo from '../DTP/HeaderNavigation/Logo';
import {
  AppBarLink,
  AppBarMenuItem,
  AppBarButton,
  AppBarProfileButton,
  AppBarProfileLink,
} from './CRHeaderNavigation.style';
import { ChildLinkItem, CRNavFields } from './CRHeaderNavigation.types';
import { useRouter } from 'next/router';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import ArrowDropUp from '@mui/icons-material/ArrowDropUp';
import PermIdentityOutlined from '@mui/icons-material/PermIdentityOutlined';
import { LinkField, ImageField } from '@sitecore-jss/sitecore-jss-react';
import { getQuery } from '../../lib/utils/common';
import { SIGN_OUT, ABOUT_US_PATH } from '../../lib/constant';

const HCP_PAGE = 'For Healthcare Professional';
const PROFILE = 'myProfile';

const fallbackLink: LinkField = { value: { href: '', text: '' } };
const fallbackImg: ImageField = { value: { src: '' } };
const CRDesktopNav: React.FC<CRNavFields> = (props) => {
  const router = useRouter();
  const { query } = router;
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [anchorElProfile, setAnchorElProfile] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const profileOpen = Boolean(anchorElProfile);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (event.currentTarget.getAttribute('id') === 'profile-button') {
      setAnchorElProfile(event.currentTarget);
      return;
    } else {
      setAnchorEl(event.currentTarget);
    }
  };
  const handleClose = () => {
    anchorEl && setAnchorEl(null);
    anchorElProfile && setAnchorElProfile(null);
  };

  const ChildMenu: React.FC<{
    navLinks: Array<ChildLinkItem>;
    anchorElm: any;
    id: string;
    btnId: string;
    isOpen: boolean;
    fieldItem?: any;
  }> = (propsChildMenu) => {
    const onClickMenuItem = (_e: any, item: any) => {
      if (item.name === SIGN_OUT) {
        props?.openProfileModal({
          open: false,
          profileFields: propsChildMenu?.fieldItem || item.fields,
          signOutOpen: true,
        });
        handleClose();
        return;
      }
      router.push({
        pathname: `${item.fields?.link?.value.href}`,
        query: getQuery(query),
      });
      handleClose();
    };
    if (Array.isArray(propsChildMenu.navLinks) && propsChildMenu.navLinks.length > 0) {
      return (
        <Menu
          sx={{
            marginTop: '10px',
          }}
          id={`menu-${propsChildMenu.id}`}
          anchorEl={propsChildMenu.anchorElm}
          open={propsChildMenu.isOpen}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': propsChildMenu.btnId,
          }}
        >
          {propsChildMenu.navLinks.map((item, index) => {
            return (
              <AppBarMenuItem
                key={`${propsChildMenu.btnId}-${index}`}
                onClick={(e) => onClickMenuItem(e, item)}
              >
                {item.name === SIGN_OUT ? (
                  <AppBarProfileLink>{item.fields?.link?.value.text}</AppBarProfileLink>
                ) : (
                  <AppBarLink field={item.fields?.link || fallbackLink}></AppBarLink>
                )}
              </AppBarMenuItem>
            );
          })}
        </Menu>
      );
    }
    return <></>;
  };
  const conditionSatisfy = () => {
    return !!(
      typeof window !== 'undefined' &&
      ABOUT_US_PATH.some((a) => (window as any)?.location?.pathname?.includes(a))
    );
  };

  return (
    <Box
      id="cr-desktop-header-navigation"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      sx={{
        width: '100%',
        marginLeft: '6rem',
        marginRight: '5rem',
        [theme.breakpoints.down(1150)]: {
          marginLeft: '4rem',
          marginRight: '3rem',
        },
      }}
    >
      <Box>
        <Logo
          id={'cr-desktop-logo'}
          imageField={props.headerLogo || fallbackImg}
          ariaLabel={props?.headerLogoAriaLabel?.value as string}
        />
      </Box>
      <Box display="flex" justifyContent="flex-end" alignItems="center" columnGap="1rem">
        {props.navigationLinks &&
          props.navigationLinks.map((item: any, index: number) => {
            const childNavList = item.fields?.childNavList;
            const isProfileLinkAsModal = item.fields?.hasOwnProperty(PROFILE);
            const isHCPLink = item?.name === HCP_PAGE;
            if (isProfileLinkAsModal) {
              if (!props.isAuthenticated) {
                return (
                  <AppBarButton
                    key={index}
                    onClick={() =>
                      props?.openProfileModal({ open: true, profileFields: item.fields })
                    }
                  >
                    {item.fields?.link?.value.text || ''}
                  </AppBarButton>
                );
              }

              return (
                <>
                  <AppBarProfileButton
                    id="profile-button"
                    key={index}
                    aria-controls={profileOpen ? `menu-${item?.id}` : undefined}
                    aria-haspopup="true"
                    aria-expanded={profileOpen ? 'true' : undefined}
                    onClick={handleClick}
                    startIcon={
                      <Avatar alt="Profile" sx={{ width: 35, height: 35 }}>
                        <PermIdentityOutlined />
                      </Avatar>
                    }
                  >
                    {item.fields?.link?.value.text || ''}
                    {profileOpen ? <ArrowDropUp /> : <ArrowDropDown />}
                  </AppBarProfileButton>
                  <ChildMenu
                    navLinks={item.fields?.childNavList || []}
                    anchorElm={anchorElProfile}
                    id={item?.id}
                    btnId="profile-button"
                    isOpen={profileOpen}
                    fieldItem={item.fields}
                  />
                </>
              );
            }

            if (isHCPLink && props.isAuthenticated) {
              return null;
            }

            if (Array.isArray(childNavList) && childNavList.length > 0) {
              return (
                <Box key={index}>
                  <AppBarButton
                    key={`basic-button-parent-${index}`}
                    id="basic-button"
                    aria-controls={open ? `menu-${item?.id}` : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                    sx={{
                      borderRadius: '50px',
                      padding: '0 8px 0 16px',
                      height: '48px',
                      '&:hover': {
                        background: '#ECF3FF',
                      },
                      ...(conditionSatisfy() && { background: '#ECF3FF' }),
                    }}
                  >
                    {item.fields?.link?.value.text || ''}
                    {open ? <ArrowDropUp /> : <ArrowDropDown />}
                  </AppBarButton>
                  <ChildMenu
                    navLinks={item.fields?.childNavList || []}
                    anchorElm={anchorEl}
                    id={item?.id}
                    btnId="basic-button"
                    isOpen={open}
                  />
                </Box>
              );
            }
            return <AppBarLink field={item.fields?.link || fallbackLink} key={index} />;
          })}
      </Box>
    </Box>
  );
};
export default CRDesktopNav;
