import Grid from '@mui/material/Grid';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import {
  Text,
  RichText,
  Field,
  ImageField,
  useSitecoreContext,
} from '@sitecore-jss/sitecore-jss-nextjs';
import { StCommunityContainer, StCommunityWrapper } from './CommunityList.style';
import { useRouter } from 'next/router';
import CommunityCard from '../../components/shared/CommunityCard/CommunityCard';
import { ComponentProps } from 'lib/component-props';
import { useCallback, useEffect, useState, useRef } from 'react';
import { getQuery } from '../../lib/utils/common';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/store/store';
import { updateCommunityExpanded, updateSelectedCommunityId } from '../../features/configSlice';
import { updateLastVisitedComponentId } from '../../features/globalConfig';
import { StCommonTypography } from '../../components/shared/Container/Container.styles';

interface CommunityItem {
  id: string; // Add the 'id' property to the CommunityItem type
  url: string;
  name: string;
  fields: {
    title: Field<string>;
    relatedCommunity: {
      fields: { icon: ImageField };
    };
  };
}

type CommunityListProps = ComponentProps & {
  fields: {
    heading: Field<string>;
    content: Field<string>;
    viewAllCommunitiesText: Field<string>;
    listOfCommunities: CommunityItem[];
    iconColor: Field<string>;
  };
};

// const getFieldsByName = (data: any, fieldName: any) => {
//   return data?.fields?.find((field: any) => field.name === fieldName);
// };

const CommunityList = (props: CommunityListProps): JSX.Element => {
  const { fields } = props;
  const { heading, content, listOfCommunities, viewAllCommunitiesText, iconColor } = fields;
  const crProfileData = useSelector((state: RootState) => state?.crProfile);
  const isCommunityExpanded = useSelector((state: RootState) => state?.config?.isCommunityExpanded);
  const selectedCommunityId = useSelector((state: RootState) => state?.config?.selectedCommunityId);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const selectedCommunities = crProfileData?.selectedCommunities || [];

  const theme = useTheme();
  const dispatch = useDispatch();
  const { sitecoreContext } = useSitecoreContext();
  const isMyProfilePage = sitecoreContext?.route?.fields?.isMyProfilePage;

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const router = useRouter();

  const { query } = router;
  const [filterListOfCommunity, setfilterListOfCommunity] = useState<any>([]);

  useEffect(() => {
    if (isMyProfilePage) {
      const data = listOfCommunities.filter((item1: any) => {
        const value1 = item1.fields?.relatedCommunity?.fields?.value?.value;
        const isExist =
          selectedCommunities &&
          selectedCommunities?.length > 0 &&
          selectedCommunities?.find((item2: any) => {
            const value2 = item2.id;
            if (value1 === value2) return item2;
          });
        if (!isExist) return item1;
      });
      setfilterListOfCommunity(data);
    } else {
      setfilterListOfCommunity(listOfCommunities);
    }
  }, [listOfCommunities, selectedCommunities, router]);

  const communityContainerRef = useRef<HTMLDivElement>(null);
  const totalCommunities = filterListOfCommunity?.length;
  const MAX_COMMUNITY_NUMBER_IN_MOBILE = 5;
  const [numberOfCommunitiesToBeShown, setnumberOfCommunitiesToBeShown] = useState(0);

  const onClickHandler = (communityItem: CommunityItem) => {
    dispatch(updateLastVisitedComponentId('community-list'));
    dispatch(updateSelectedCommunityId(communityItem?.id));
    router.push(
      {
        pathname: communityItem?.url,
        query: getQuery(query),
      },
      communityItem?.url
    );
  };

  const showAllCommunities = useCallback(() => {
    const allCommunitiesElement: NodeListOf<HTMLElement> =
      document.querySelectorAll('div[role=link]');
    for (const element of allCommunitiesElement) {
      element.setAttribute('aria-hidden', 'false');
      element.classList.remove('hideElement');
    }
  }, []);

  const handleShowHideCommunityItems = useCallback(() => {
    if (sitecoreContext?.pageEditing === true || numberOfCommunitiesToBeShown === totalCommunities)
      return;

    const allCommunitiesElement: NodeListOf<HTMLElement> =
      document.querySelectorAll('div[role=link]');
    const communityContainerElement = communityContainerRef.current as HTMLDivElement;

    let visibleCommunityCount = 0;
    if (window.innerWidth < theme.breakpoints.values.sm) {
      let counter = 0;
      for (const element of allCommunitiesElement) {
        if (counter < MAX_COMMUNITY_NUMBER_IN_MOBILE) {
          counter++;
          element.setAttribute('aria-hidden', 'false');
          element.classList.remove('hideElement');
        } else {
          element.setAttribute('aria-hidden', 'true');
          element.classList.add('hideElement');
        }
      }
      setnumberOfCommunitiesToBeShown(counter);
    } else {
      const threshold =
        communityContainerElement?.offsetTop + allCommunitiesElement[0]?.offsetHeight * 2;

      for (const element of allCommunitiesElement) {
        if (element.offsetTop < threshold) {
          visibleCommunityCount++;
          element.setAttribute('aria-hidden', 'false');
          element.classList.remove('hideElement');
        } else {
          element.setAttribute('aria-hidden', 'true');
          element.classList.add('hideElement');
        }
      }
      setnumberOfCommunitiesToBeShown(visibleCommunityCount);
    }
    communityContainerElement.classList.remove('hideElement');
  }, [
    theme.breakpoints.values.sm,
    sitecoreContext?.pageEditing,
    numberOfCommunitiesToBeShown,
    totalCommunities,
  ]);

  const handleViewAllLinkClick = useCallback(() => {
    dispatch(updateCommunityExpanded(true));
    setnumberOfCommunitiesToBeShown(totalCommunities);
    showAllCommunities();
  }, [dispatch, showAllCommunities, totalCommunities]);

  useEffect(() => {
    setTimeout(() => {
      handleShowHideCommunityItems();
    }, 0);
  }, [handleShowHideCommunityItems]);

  useEffect(() => {
    window.addEventListener('resize', handleShowHideCommunityItems);
    return () => {
      window.removeEventListener('resize', handleShowHideCommunityItems);
    };
  }, [handleShowHideCommunityItems]);

  useEffect(() => {
    if (isCommunityExpanded) {
      setTimeout(() => {
        setnumberOfCommunitiesToBeShown(totalCommunities);
        showAllCommunities();
      }, 1000);
    }
  }, [showAllCommunities, isCommunityExpanded, totalCommunities]);

  const renderViewAllCommunityLink = (): JSX.Element | null => {
    if (
      totalCommunities !== numberOfCommunitiesToBeShown ||
      sitecoreContext?.pageEditing === true
    ) {
      return <Text field={viewAllCommunitiesText} />;
    }
    return null;
  };
  if (!props?.fields) return <></>;

  if (totalCommunities < 1) {
    return <></>;
  }
  return (
    <StCommunityWrapper
      id="community-list"
      sx={{
        ...(isMyProfilePage && {
          backgroundColor: theme.palette.backgroundColorType3.main,
        }),
      }}
    >
      <StCommunityContainer container item>
        <Grid item data-testid="heading">
          <StCommonTypography
            variant="h2"
            sx={{
              lineHeight: '1.5rem',
              fontWeight: 600,
              textAlign: 'center',
              fontSize: '2rem',
              [theme.breakpoints.down('sm')]: {
                fontSize: '24px',
                ...(isMyProfilePage && filterListOfCommunity?.length > 0 && { textAlign: 'start' }),
              },
            }}
          >
            <Text field={heading} />
          </StCommonTypography>
        </Grid>
        <Grid
          item
          data-testid="content"
          sx={{ textAlign: 'center', marginTop: '1rem', fontSize: '1.125rem', fontWeight: 500 }}
        >
          <RichText field={content} />
        </Grid>
        <Grid
          ref={communityContainerRef}
          id="community-list-container"
          item
          className={sitecoreContext?.pageEditing === false ? 'hideElement' : ''}
          container
          sx={{ justifyContent: 'center', marginTop: '1rem' }}
        >
          {filterListOfCommunity &&
            filterListOfCommunity.map((communityItem: CommunityItem, index: number) => (
              <CommunityCard
                key={index}
                communityItem={communityItem}
                communityCardColor={iconColor?.value}
                isMobile={isMobile}
                onClickHandler={onClickHandler}
                onKeyUp={(e, communityItem) => {
                  if (e.key === 'Enter') {
                    onClickHandler(communityItem as any);
                  }
                }}
                sxCommunityCardProps={{
                  background: theme.palette.neutral0.main,
                  border:
                    selectedCommunityId === communityItem?.id
                      ? `1px solid ${theme.palette.primary.main}`
                      : `1px solid ${theme.palette.neutral4.main}`,
                  borderRadius: '10px',
                  alignItems: 'center',
                  padding: '1rem',
                  cursor: 'pointer',
                  flexWrap: 'nowrap !important',
                  '&:hover': {
                    border: `1px solid ${theme.palette.primary.main}`,
                    background: theme.palette.secondary.dark,
                    color: theme.palette.primary.main,
                  },
                }}
                sxCommunityCardTitleProps={{
                  fontSize: '1.125rem',
                  fontWeight: 500,
                  [theme.breakpoints.down('sm')]: {
                    marginLeft: '1rem',
                  },
                  [theme.breakpoints.up('sm')]: {
                    marginTop: '0.5rem',
                  },
                }}
              />
            ))}
        </Grid>
        <Grid item sx={{ marginTop: '1rem', cursor: 'pointer', textAlign: 'center' }}>
          <StCommonTypography
            role="link"
            tabIndex={0}
            variant="title"
            aria-label={viewAllCommunitiesText?.value || 'View All Communities'}
            aria-expanded={totalCommunities !== numberOfCommunitiesToBeShown ? 'false' : 'true'}
            onClick={() => {
              handleViewAllLinkClick();
            }}
            onKeyUp={(e) => {
              if (e.key === 'Enter') {
                handleViewAllLinkClick();
              }
            }}
            sx={{
              lineHeight: '2rem',
              fontSize: '20px !important',
              color: theme.palette.primary.main,
              curser: 'pointer !important',
            }}
          >
            {renderViewAllCommunityLink()}
          </StCommonTypography>
        </Grid>
      </StCommunityContainer>
    </StCommunityWrapper>
  );
};

export default CommunityList;
