import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Text } from '@sitecore-jss/sitecore-jss-nextjs';
import { StCheckBoxLabel } from './CheckboxWithLabel.styles';

const CheckboxWithLabel = (props: any): JSX.Element => {
  return (
    <FormControlLabel
      control={
        <Checkbox
          size="small"
          checked={props?.checked}
          onChange={(event) =>
            props?.handleChange(event, props?.name?.value, props?.index, props?.type)
          }
          sx={{ alignSelf: 'flex-start', padding: 0, paddingInline: '8px' }}
        />
      }
      label={
        <StCheckBoxLabel>
          <Text field={props?.label} />
        </StCheckBoxLabel>
      }
    />
  );
};

export default CheckboxWithLabel;
