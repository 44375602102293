import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';

import { NextImage, RichText } from '@sitecore-jss/sitecore-jss-nextjs';
import { JSSLink } from 'components/shared/Links/JSSLink.styles';
import {
  StRichTextGridItem,
  StTitle,
  StBannerContainer,
  StBannerWrapper,
  StBannerContent,
  StEllipseBox,
  StBoxWrapper,
} from './TAHeroBanner.styles';
import { TAHeroBannerProps } from './TAHeroBanner.types';
// import { useSelector } from 'react-redux';
// import { RootState } from '../../store/store';

import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';

const TAHeroBanner = (props: TAHeroBannerProps): JSX.Element => {
  const theme = useTheme();
  const bannerProps = props?.fields || {};
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const isTablet = useMediaQuery(theme.breakpoints.between(500, 'lg'));
  const isMobile = useMediaQuery(theme.breakpoints.between('xs', 500));
  const { sitecoreContext } = useSitecoreContext();
  // const isNotificationBannerOpen = useSelector(
  //   (state: RootState) => state.config.isNotificationBannerOpen
  // );
  if (!props?.fields) return <></>;

  const BannerImage = (): JSX.Element => {
    return (
      <NextImage
        priority={true}
        field={
          isMobile
            ? bannerProps?.mobileImage
            : isTablet
            ? bannerProps?.tabletImage
            : bannerProps?.imageDesktop
        }
        quality={100}
        style={{ zIndex: 0 }}
        layout="fill"
        objectFit="cover"
        objectPosition="center"
      ></NextImage>
    );
  };

  const SectionItems = (): JSX.Element => {
    return (
      <StBoxWrapper isMobile={isMobile} isTablet={isTablet}>
        <StTitle>
          <RichText field={bannerProps?.title}></RichText>
        </StTitle>
        <StRichTextGridItem isTablet={isTablet} isMobile={isMobile}>
          <RichText field={bannerProps?.content}></RichText>
        </StRichTextGridItem>
        <JSSLink
          key={bannerProps?.ctaButtons[0]?.id}
          className={'ta-hero-cta-btn btn'}
          variant="contained"
          field={bannerProps?.ctaButtons[0]?.fields?.link}
          onClick={(e) => scrollToSection(e)}
          sx={{
            alignSelf: isTablet || isMobile ? 'center' : 'none',
            marginBottom: isTablet || isMobile ? '100px' : '0px',
            [theme.breakpoints.between('xs', 500)]: {
              lineHeight: '2.5rem',
            },
          }}
        />
      </StBoxWrapper>
    );
  };

  //debugger
  //console.log("isOpen", isNotificationBannerOpen);
  const scrollToSection = (e: any) => {
    e.preventDefault();

    if (e) {
      const itemId = e.currentTarget.hash ? e.currentTarget.hash.split('#')[1] : undefined;
      const itemOffset = document.getElementById(itemId)?.offsetTop;

      const isBannerEnabledFromSitecore = sitecoreContext?.isNotificationBannerOpen ?? true;
      const notificationBanner = document.getElementById('notification-banner');
      const isNotificationBannerOpen = isBannerEnabledFromSitecore && notificationBanner !== null;
      //const isNotificationBannerOpen = document.getElementById('notification-banner') !== null; // scrolling position fix

      const margin = isNotificationBannerOpen ? 50 : 0;
      if (itemOffset) {
        window.scroll({
          top:
            itemOffset -
            (isNotificationBannerOpen === null ? 0 : margin) +
            (isNotificationBannerOpen ? 50 : 0),
          //top: itemOffset - (isNotificationBannerOpen === null ? 0 : margin),
          //top: itemOffset - margin + 50,
          left: 0,
          behavior: 'smooth',
        });
      }
    }
  };

  return (
    <>
      {!isDesktop && (
        <div id="hero-banner">
          <Box
            sx={{
              position: 'relative',
              width: '100%',
              height: '100%',
              overflow: isMobile || isTablet ? 'hidden' : 'unset',
            }}
          >
            <StEllipseBox isMobile={isMobile} isTablet={isTablet} />
            <SectionItems />
            <BannerImage />
          </Box>
        </div>
      )}
      {isDesktop && (
        <StBannerWrapper id="hero-banner">
          <BannerImage />
          <StBannerContainer id="ta-hero-content" container>
            <Stack>
              <StBannerContent item xs={12} sm={12} md={12} lg={12}>
                <Grid
                  item
                  sx={{
                    position: 'relative',
                    [theme.breakpoints.down('sm')]: {
                      maxWidth: '450px',
                    },
                  }}
                >
                  <StEllipseBox isMobile={isMobile} isTablet={isTablet} />
                  <SectionItems />
                </Grid>
              </StBannerContent>
            </Stack>
          </StBannerContainer>
        </StBannerWrapper>
      )}
    </>
  );
};

export default TAHeroBanner;
