/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../store/store';
import { UIIframe } from '../shared/UIIframe';
import { SurveyDetails } from './Survey.types';
import { GetSurveyPostData } from './SurveyData.service';
import { JOURNEY_STEP } from '../../constants';
import { getOrCreateEnrollmentId } from '../../lib/utils/enrollmentUtil';
import { useSitecoreContext, isEditorActive } from '@sitecore-jss/sitecore-jss-nextjs';
import { JOURNEYS } from '../../constants/index';
import {
  StDTPGridContainer,
  StDTPWrapper,
} from '../../components/shared/Container/Container.styles';
import { updateCurrentJourneyInCDP } from '../../services/CdpService';
import pickBy from 'lodash/pickBy';
import isEmpty from 'lodash/isEmpty';
import { getQueryParams } from '../../lib/utils/common';
import axios from 'axios';
import { ROOT_PATH_ID } from '../../lib/constant';
import { setConditionItems, setLastQuestionSubmitted } from '../../features/multiScreenerSlice';
import LoaderWithText from '../../components/shared/Loader/LoaderWithText';
import { hideLoader } from '../../features/loaderSlice';
import { useRouter } from 'next/router';

const PreScreenerQuestionnaire: React.FC<SurveyDetails> = (props) => {
  const dispatch = useDispatch();
  const router = useRouter();
  const { query } = router;
  const [isShowLoader, setIsShowLoader] = useState(false);
  const potentialParticipantDetailsFromStore = useSelector(
    (state: RootState) => state.potentialParticipantDetails
  );
  const searchQueryData = useSelector((state: RootState) => state?.queryParams);
  const cdpState = useSelector((state: RootState) => state.cdpData);
  const multiScreenerData = useSelector((state: RootState) => state?.multiScreener);
  const journeyType = useSelector((state: RootState) => state.cdpData.journeyType);
  const isLoading = useSelector((state: RootState) => state.loader.isLoading);
  const isLastQuestionSubmitted: any =
    useSelector((state: RootState) => state?.multiScreener?.isLastQuestionSubmitted) || false;

  const { potentialParticipant_ID, potentialId } = potentialParticipantDetailsFromStore;
  const { sitecoreContext } = useSitecoreContext();
  const language = sitecoreContext?.language || 'en';
  // @ts-ignore
  const isHomePage = sitecoreContext?.route?.fields?.isHomePage?.value || false;
  // @ts-ignore
  const isPrescreenerPage = sitecoreContext?.route?.fields?.isPreScreenerPage?.value || false;
  const postDataFromScreener =
    (!isEmpty(multiScreenerData?.postDataFromScreener) &&
      multiScreenerData?.postDataFromScreener) ||
    [];
  const genericAnswerObj =
    (!isEmpty(postDataFromScreener) && postDataFromScreener[postDataFromScreener.length - 1]) || {};
  const isJourneyType3: boolean = journeyType === JOURNEYS.JOURNEY_03;

  const siteSettings: any = sitecoreContext?.siteSettings;
  const enableMultiConditionRouting = props?.fields?.enableMultiConditionRouting?.value || false;
  const loaderTextMessage = props?.fields?.loaderText?.value || '';
  const isScreenerLandingPageEnabled = siteSettings?.enableScreenerLandingPage?.value;

  //Updating CDP Journey Step
  const updateCurrentJourneyStepinCDP = async () => {
    const payload = {
      enrollmentId: getOrCreateEnrollmentId(),
      journeyStep: JOURNEY_STEP.SCREENER,
      journeyStatus: '',
      isActiveEnrollment: '1',
    };

    if (potentialParticipant_ID) {
      const guestType = cdpState?.guestType || 'visitor';
      updateCurrentJourneyInCDP(potentialParticipant_ID, guestType, payload);
    }
  };

  const fetchConditionItems = async () => {
    try {
      const response = await axios.post('/api/conditionItemIds', {
        language,
        rootpath: ROOT_PATH_ID,
      });
      if (
        response.status === 200 &&
        !isEmpty(response?.data) &&
        !isEmpty((response as any)?.data?.ConditionItemIdsList) &&
        (response as any)?.data?.ConditionItemIdsList.length > 0
      )
        dispatch(setConditionItems((response as any)?.data?.ConditionItemIdsList));
    } catch (error) {}
  };

  const checkStudiesAttemptOrNot = () => {
    const attemptStudies = multiScreenerData?.attemptStudies || [];
    const attemptStudiesQuery = attemptStudies?.map((a: any) => a?.toLowerCase())?.join(',');
    const path = Array.isArray(query?.path) ? query?.path?.join(',') : query?.path;
    if (attemptStudiesQuery.includes(path)) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (isPrescreenerPage && potentialParticipantDetailsFromStore?.potentialParticipant_ID) {
      updateCurrentJourneyStepinCDP();
    }
  }, [potentialParticipantDetailsFromStore]);

  useEffect(() => {
    if (isEditorActive() || sitecoreContext?.pageState === 'preview') {
      return;
    }
    fetchConditionItems();
  }, [isPrescreenerPage]);

  useEffect(() => {
    dispatch(hideLoader());
    dispatch(setLastQuestionSubmitted(false));
  }, []);

  if (!props?.fields) return <></>;
  if ((isHomePage && isJourneyType3) || isLoading) return null;
  if (isScreenerLandingPageEnabled && isHomePage) return <></>;

  // const postData = GetSurveyPostData('http://localhost:3000/');
  // Send baseurl to survey to set it as targetOrigin for survey
  const postData = GetSurveyPostData(props?.fields?.baseUrl?.value?.href);

  // const surveyLink = 'https://survey.alchemer.com/s3/7297073/Re-write-Dev-CVD-Studies-screener/123';
  let surveyLink = props?.fields?.surveyUrl?.value?.href;
  if (!isEmpty(genericAnswerObj?.genericAnswers)) {
    const urlParams = Object.keys(genericAnswerObj?.genericAnswers)
      .map((key) => key + '=' + genericAnswerObj?.genericAnswers?.[key])
      .join('&');
    if (urlParams && !checkStudiesAttemptOrNot())
      surveyLink = surveyLink + `${surveyLink.includes('?') ? '&' : '/?'}` + urlParams;
  }

  const { screenerResponseID, screenerCompleted } = cdpState;
  if (screenerResponseID && screenerCompleted === 'false') {
    surveyLink = surveyLink + `/?snc=${screenerResponseID}`;
  }

  if (siteSettings?.baseUrl?.value || props?.fields?.baseUrl?.value?.href) {
    const baseURLVal = siteSettings?.baseUrl?.value || props?.fields?.baseUrl?.value?.href;
    if (baseURLVal)
      surveyLink =
        surveyLink + `${surveyLink.includes('?') ? '&' : '/?'}` + 'baseURL=' + baseURLVal;
  }

  if (!isEmpty(searchQueryData)) {
    const queryData = pickBy(searchQueryData, (value) => !!value?.length);
    const urlParams = getQueryParams(queryData);
    if (urlParams) surveyLink = surveyLink + `${surveyLink.includes('?') ? '&' : '/?'}` + urlParams;
  }

  const srcUrl = decodeURIComponent(`${surveyLink}`);

  const getUIIframeComponent = () => {
    if ((potentialParticipant_ID === '' || potentialId === '') && enableMultiConditionRouting) {
      return <></>;
    }
    return (
      <UIIframe
        id="pre-screener-questionnaire-iframe"
        title="Iframe to display prescreener questionnaire"
        testId="pre-screener-questionnaire-iframe"
        src={srcUrl}
        messageToPost={postData}
        nextPageUrl={props?.fields?.contactInfoUrl}
        enableMultiConditionRouting={enableMultiConditionRouting}
        setIsShowLoader={setIsShowLoader}
      />
    );
  };

  return (
    <StDTPWrapper id="pre-screener-questionnaire">
      <StDTPGridContainer>{getUIIframeComponent()}</StDTPGridContainer>
      {isLastQuestionSubmitted && isShowLoader && <LoaderWithText message={loaderTextMessage} />}
    </StDTPWrapper>
  );
};

export default PreScreenerQuestionnaire;
