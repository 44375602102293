import { useEffect, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { setAuthentication } from '../../features/authSlice';
import { handleLogout } from './useAuthentication';
import { useRouter } from 'next/router';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import { resetProfileData } from '../../features/crProfileSlice';

const useAuthIdleTimer = (): {
  showLogOutModal: boolean;
  setLogOutModal: React.Dispatch<React.SetStateAction<boolean>>;
  promptBeforeLogout: boolean;
  handleIdleLogout: (params?: string) => void;
  continueSession: () => void;
} => {
  const [showLogOutModal, setLogOutModal] = useState<boolean>(false);
  const [promptBeforeLogout, setPromptBeforeLogout] = useState(false);
  const dispatch = useDispatch();
  const { sitecoreContext }: any = useSitecoreContext();
  const idleSessionPopup = sitecoreContext?.idleSessionPopup;
  const idleTimeout: number = (idleSessionPopup?.idleSignoutSessionTime?.value || 20) * 60 * 1000;
  const promptBeforeIdle: number =
    (idleSessionPopup?.promptSignoutSessionTime?.value || 10) * 60 * 1000;
  const router = useRouter();
  const isAuthenticated = useSelector((state: RootState) => state?.authentication?.isAuthenticated);
  const onPrompt = () => {
    setPromptBeforeLogout(true);
  };

  const handleIdleLogout = (params = '') => {
    dispatch(setAuthentication(false));
    dispatch(resetProfileData());
    handleLogout();
    router.pathname !== '/' && router.push('/');
    promptBeforeLogout && setPromptBeforeLogout(false);
    params !== 'onSignOut' && setLogOutModal(true);
  };

  const { reset, activate } = useIdleTimer({
    timeout: idleTimeout,
    promptBeforeIdle,
    onPrompt,
    onIdle: () => {
      if (isAuthenticated) {
        handleIdleLogout();
      }
    },
    debounce: 500,
    disabled: !isAuthenticated,
  });

  const continueSession = () => {
    setPromptBeforeLogout(false);
    activate();
  };

  useEffect(() => {
    if (isAuthenticated) {
      reset();
    }
  }, [isAuthenticated, reset]);

  return { showLogOutModal, setLogOutModal, handleIdleLogout, promptBeforeLogout, continueSession };
};

export default useAuthIdleTimer;
