import React from 'react';
import CRModal from '.';
import Box from '@mui/material/Box';
import { ModalCTAButton, SignOutFooterModal } from './FooterModal';
import { RichText } from '@sitecore-jss/sitecore-jss-nextjs';
import { useDispatch } from 'react-redux';
import { setAuthentication } from '../../../features/authSlice';
//import { useRouter } from 'next/router';
import { resetProfileData } from '../../../features/crProfileSlice';
import { setCookie } from 'cookies-next';
import { getCookieExpiryTime } from '../../../../src/lib/utils/enrollmentUtil';

interface ModalProps {
  modal: {
    open: boolean;
    onClose: () => void;
  };
  fieldProps: {
    modalCTAButtons: ModalCTAButton[];
    modalTitle?: { value: string };
    modalDescription?: { value: string };
  };
}
const resetCookies = () => {
  setCookie('enrollmentID', null, {
    expires: getCookieExpiryTime(0),
    secure: true,
  });
  setCookie('journeyStatus', '', {
    expires: getCookieExpiryTime(0),
    secure: true,
  });
  setCookie('journeyStep', '', {
    expires: getCookieExpiryTime(0),
    secure: true,
  });
  setCookie('journeyType', '', {
    expires: getCookieExpiryTime(0),
    secure: true,
  });
  setCookie('bx_guest_ref', '', {
    expires: getCookieExpiryTime(0),
    secure: true,
  });
  const cookieName = 'bid_' + process.env.NEXT_PUBLIC_CDP_CLIENT_KEY;
  setCookie(cookieName, '', {
    secure: true,
    expires: getCookieExpiryTime(0),
  });
};
const SignOutModal: React.FC<ModalProps> = ({ modal: { open, onClose }, fieldProps }) => {
  const { modalCTAButtons = [], modalTitle, modalDescription } = fieldProps;
  //const router = useRouter();
  const dispatch = useDispatch();
  const onSignOut = () => {
    dispatch(setAuthentication(false));
    dispatch(resetProfileData());
    sessionStorage.removeItem('myProfileVerificationToken');
    resetCookies();
    // router.pathname !== '/' && router.push('/');
    window.location.href = '/';
    onClose();
  };
  return (
    <CRModal open={open} handleClose={onClose} handleCancel={onClose} title={modalTitle?.value}>
      <Box sx={{ color: '#444444', width: '100%' }}>
        <RichText field={{ value: modalDescription?.value }} />
        <SignOutFooterModal
          modalCTAButtons={modalCTAButtons}
          onClose={onClose}
          onSignOut={onSignOut}
        />
      </Box>
    </CRModal>
  );
};

export default SignOutModal;
