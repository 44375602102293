import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import { StInputBase } from './TextBox.style';
import { useTheme } from '@mui/material/styles';

const TextBox = (props: any): JSX.Element => {
  const theme = useTheme();
  return (
    <FormControl fullWidth>
      <StInputBase
        placeholder={props?.placeholder}
        onChange={(event) => props?.onChangeHandler(event, props?.name)}
        required={props?.required}
        error={props?.error}
        value={props?.value}
        inputProps={{
          color: theme.palette.neutral11.main,
          maxLength: props?.maxLength,
        }}
      />
      {props?.regexError && (
        <Typography
          sx={{
            color: theme.palette.error.main,
            fontSize: '13px',
            fontFamily: 'Proxima Nova',
            lineHeight: '24px',
            fontWeight: 400,
          }}
        >
          {props?.regexMessage.value}
        </Typography>
      )}
    </FormControl>
  );
};

export default TextBox;
