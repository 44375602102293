import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { updatepotentialParticipantDetails } from '../../features/potentialParticipantDetailsSlice';
import { Engage } from '@sitecore/engage';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import {
  ENROLLMENT_EXPIRY_TIME_FALLBACK,
  getCookie,
  getCookieExpiryTime,
  getJourneyTypeCookie,
  getOrCreateEnrollmentId,
  setJourneyTypeCookie,
} from '../../lib/utils/enrollmentUtil';
import { JOURNEY_STATUS } from '../../constants';
import { useRouter } from 'next/router';
import {
  checkForResumeUserForTALevelEPR,
  decryptTokenForResumeJourney,
} from '../../lib/utils/axiosCDPFetcher';
import { setCookie } from 'cookies-next';
import {
  fetchNextPossiblePathAndData,
  EXPERIENCE_ID_MAPPER,
  getJourneyId,
} from '../../services/CdpService';
import { addpotentialId } from '../../features/potentialParticipantDetailsSlice';
import useJourneyModal from './useJourneyModal';
import { addJourneyType, resetCDPData, storeCDPData } from '../../features/cdpDataSlice';
import { showLoader, hideLoader } from '../../features/loaderSlice';
import isEmpty from 'lodash/isEmpty';
import { Field, isEditorActive } from '@sitecore-jss/sitecore-jss-nextjs';
import axios from 'axios';
// import { RootState } from '../../store/store';

interface IEngage {
  exposedFunctions: Engage;
  [x: string]: any;
}
declare global {
  interface Window {
    Engage: IEngage;
  }
}

interface IUseSitecoreEngage {
  clientKey: string;
  targetURL: string;
  pointOfSale: string | undefined;
  cookieDomain: string;
  cookieExpiryDays: number;
  forceServerCookieMode: boolean;
  includeUTMParameters: boolean;
  webPersonalization: boolean;
  scriptUrl: string;
}
const useSitecoreEngage = (
  config: IUseSitecoreEngage,
  route: any
): { isCDPScriptLoaded: boolean; triggerStartOver: boolean } => {
  const dispatch = useDispatch();
  const [guestId, setGuestId] = useState(null);
  const [triggerStartOver, setTriggerStartOver] = useState(false);
  const isCDPScriptLoaded = useRef<boolean>(false);
  const router = useRouter();
  const { sitecoreContext } = useSitecoreContext();
  const siteSettings: any = sitecoreContext?.siteSettings;
  const tenantKey = siteSettings?.tenantKey?.value || '';
  const journeyId = siteSettings?.journeyType?.fields?.journeyId?.value || '';
  const projectTherapeuticArea = siteSettings?.projectTherapeuticArea?.fields?.key?.value || '';
  const source = siteSettings?.source?.fields?.name?.value || '';
  const dataControllerOrg = siteSettings?.dataControllerOrg?.fields?.name?.value || '';
  const resumeLinkModal: any = sitecoreContext?.resumeLinkModal;
  const retryCount = useRef<number>(0);
  const isEprLandingPage = (route?.fields?.isHomePage as Field)?.value;
  // const isAuthenticated: boolean = useSelector(
  //   (state: RootState) => state.authentication.isAuthenticated
  // );
  // Callback to handle script load and initialization
  const handleScriptLoad = async (gId: any) => {
    let engage: any = undefined;
    if (window.Engage && typeof window.Engage.init === 'function') {
      const initEngage = async (): Promise<any> => {
        try {
          engage = await window.Engage.init(config);
        } catch (_error) {
          if (retryCount.current < 5) {
            retryCount.current++;
            await initEngage();
          }
        }
      };

      const handleGuestId = async (engage: any) => {
        if (engage && engage.getGuestId && typeof engage.getGuestId === 'function') {
          const existingGuestId: any = getCookie('bx_guest_ref');
          const guestId = await engage.getGuestId();
          if (gId) {
            //TODO: need to re-visit this logic- keeping this timeout to fix overrite issue
            setTimeout(() => {
              setCookie('bx_guest_ref', gId, {
                secure: true,
                expires: getCookieExpiryTime(
                  siteSettings?.resumeEnrollmentExpirationDays?.value ||
                    ENROLLMENT_EXPIRY_TIME_FALLBACK
                ),
              });
              setGuestId(gId);
            }, 2000);
          } else if (!existingGuestId) {
            setGuestId(guestId);
          } else {
            setGuestId(existingGuestId);
          }
          isCDPScriptLoaded.current = true;
          window.Engage.exposedFunctions = engage;
        } else {
          engage = await window.Engage.init(config);
          await handleGuestId(engage);
        }
      };
      await initEngage();

      await handleGuestId(engage);
    }
  };

  const { setJourneyModal: setExpiredEmailModal, showJourneyModal: showExpiredEmailModal } =
    useJourneyModal({
      title: { value: resumeLinkModal?.expireLinkHeading?.value },
      description: {
        value: resumeLinkModal?.expireLinkDescription?.value,
      },
      customButtons: [
        {
          name: resumeLinkModal?.expireLinkCtaText?.value,
          callback: () => {
            setTriggerStartOver(true);
          },
        },
      ],
    });
  const {
    setJourneyModal: setCompletedEnrollmentModal,
    showJourneyModal: showCompletedEnrollmentModal,
  } = useJourneyModal({
    title: { value: resumeLinkModal?.completedEnrollmentHeading?.value },
    description: {
      value: resumeLinkModal?.completedEnrollmentDescription?.value,
    },
    customButtons: [
      {
        name: resumeLinkModal?.completedEnrollmentCtaText?.value,
        callback: () => {
          setTriggerStartOver(true);
        },
      },
    ],
  });
  const {
    setJourneyModal: setEnrollmentTerminatedModal,
    showJourneyModal: showEnrollmentTerminatedModal,
  } = useJourneyModal({
    title: { value: resumeLinkModal?.enrollmentTerminatedHeading?.value },
    description: {
      value: resumeLinkModal?.enrollmentTerminatedDescription?.value,
    },
    customButtons: [
      {
        name: resumeLinkModal?.enrollmentTerminatedCtaText?.value,
        callback: () => {
          setTriggerStartOver(true);
        },
      },
    ],
  });

  const decryptToken = async (resumeJourneyToken: string) => {
    return await decryptTokenForResumeJourney(`/api/contacts/GetResumeJourneyDetails`, {
      resumeJourneyToken: resumeJourneyToken,
      resumeLinkExprirationDays: siteSettings?.resumeLinkExpirationDays?.value,
      resumeEnrollmentExprirationDays: siteSettings?.resumeEnrollmentExpirationDays?.value,
    });
  };

  const resetCookiesForTALevelEPR = () => {
    setCookie('enrollmentID', null, {
      expires: getCookieExpiryTime(0),
      secure: true,
    });
    setCookie('journeyStatus', '', {
      expires: getCookieExpiryTime(0),
      secure: true,
    });
    setCookie('journeyStep', '', {
      expires: getCookieExpiryTime(0),
      secure: true,
    });
    setCookie('journeyType', '', {
      expires: getCookieExpiryTime(0),
      secure: true,
    });
  };

  useEffect(() => {
    (async function () {
      if (
        isCDPScriptLoaded.current ||
        !router.isReady ||
        isEditorActive() ||
        sitecoreContext?.pageState === 'preview'
      )
        return;
      // Create a script element to load the Sitecore Engage script
      const script = document.createElement('script');
      const loadScript = (guestId: any) => {
        script.src = config.scriptUrl; // The URL of the Sitecore Engage script
        script.defer = true;

        // Append the script to the document head to load it
        document.head.appendChild(script);

        // Event listener to handle script load
        script.onload = () => handleScriptLoad(guestId);
      };
      if (router?.query?.resumeJourneyToken) {
        //from personalised email link
        try {
          dispatch(showLoader());
          const { data } = await decryptToken(router?.query?.resumeJourneyToken as string);
          setCookie('bx_guest_ref', data?.guestRef, {
            secure: true,
            expires: getCookieExpiryTime(
              siteSettings?.resumeEnrollmentExpirationDays?.value || ENROLLMENT_EXPIRY_TIME_FALLBACK
            ),
          });

          setCookie('journeyStep', data?.journeyStep, {
            secure: true,
            expires: getCookieExpiryTime(
              siteSettings?.resumeEnrollmentExpirationDays?.value || ENROLLMENT_EXPIRY_TIME_FALLBACK
            ),
          });
          if (data?.journeyStatus !== JOURNEY_STATUS.COMPLETED) {
            setCookie('journeyStatus', data?.journeyStatus, {
              secure: true,
              expires: getCookieExpiryTime(
                siteSettings?.resumeEnrollmentExpirationDays?.value ||
                  ENROLLMENT_EXPIRY_TIME_FALLBACK
              ),
            });
          } else {
            setCookie('journeyStatus', JOURNEY_STATUS.PARTIAL, {
              secure: true,
              expires: getCookieExpiryTime(
                siteSettings?.resumeEnrollmentExpirationDays?.value ||
                  ENROLLMENT_EXPIRY_TIME_FALLBACK
              ),
            });
          }
          setCookie('enrollmentID', data?.enrollmentId, {
            secure: true,
            expires: getCookieExpiryTime(
              siteSettings?.resumeEnrollmentExpirationDays?.value || ENROLLMENT_EXPIRY_TIME_FALLBACK
            ),
          });
          setCookie('journeyType', data?.journeyType, {
            secure: true,
            expires: getCookieExpiryTime(
              siteSettings?.resumeEnrollmentExpirationDays?.value || ENROLLMENT_EXPIRY_TIME_FALLBACK
            ),
          });
          const cookieName = 'bid_' + process.env.NEXT_PUBLIC_CDP_CLIENT_KEY;
          setCookie(cookieName, data?.browserId, {
            secure: true,
            expires: getCookieExpiryTime(
              siteSettings?.resumeEnrollmentExpirationDays?.value || ENROLLMENT_EXPIRY_TIME_FALLBACK
            ),
          });
          dispatch(addpotentialId(data?.potentialId));
          loadScript(data?.guestRef);
          dispatch(hideLoader());
          if (
            data?.isEnrollmentExpired === false &&
            data?.isLinkExpired === false &&
            data?.journeyStatus !== JOURNEY_STATUS.COMPLETED &&
            data?.journeyStatus !== 'Terminated'
          ) {
            const journeyStep = getCookie('journeyStep') as string;
            const experienceId = EXPERIENCE_ID_MAPPER[journeyStep];
            const res = await fetchNextPossiblePathAndData(
              experienceId,
              false,
              false,
              sitecoreContext?.site?.name
            );

            if (res?.nextStepUrl && res?.nextStepUrl !== '/') {
              await router.push(res?.nextStepUrl);
            }
            dispatch(storeCDPData(res));
          } else if (data?.journeyStatus === JOURNEY_STATUS.COMPLETED) {
            setTimeout(() => {
              setCookie('journeyStatus', data?.journeyStatus, {
                secure: true,
                expires: getCookieExpiryTime(
                  siteSettings?.resumeEnrollmentExpirationDays?.value ||
                    ENROLLMENT_EXPIRY_TIME_FALLBACK
                ),
              });
              setCompletedEnrollmentModal();
              showCompletedEnrollmentModal();
            }, 1000);
          } else if (data?.journeyStatus === 'Terminated') {
            setEnrollmentTerminatedModal();
            showEnrollmentTerminatedModal();
          } else {
            //showing link error popup (link expired or enrollement expired case)

            setExpiredEmailModal();
            showExpiredEmailModal();
          }
        } catch (_e) {
          dispatch(hideLoader());
        } finally {
        }
      } else {
        //normal flow
        loadScript(undefined);
      }
      // Clean up the script when the component unmounts
      return () => {
        document.head.removeChild(script);
      };
    })();
  }, [router.isReady]);

  const checkForResumeUserForTALevel = async () => {
    return await checkForResumeUserForTALevelEPR(
      `/api/contacts/FetchTAResumingJourneyDetailsBasedOnTenantKey`,
      {
        tenantKey: siteSettings?.tenantKey?.value,
        guestRef: getCookie('bx_guest_ref') || guestId,
        selectedLanguage: router.locale || 'en',
      }
    );
  };

  useEffect(() => {
    (async () => {
      if (guestId && isEprLandingPage) {
        let data: any;
        if (source === 'CR') {
          const eprNameFromCookie = getCookie('epr-name');
          if (eprNameFromCookie !== window.location.pathname) {
            resetCookiesForTALevelEPR();
            const cookie_expiry = new Date();
            cookie_expiry.setDate(cookie_expiry.getDate() + 10e5);
            setCookie('epr-name', window.location.pathname, {
              secure: true,
              expires: cookie_expiry,
            });
            setCookie('tenantKey', siteSettings?.tenantKey?.value, {
              expires: cookie_expiry,
              secure: true,
            });

            const pathNameArray = window.location.pathname.split('/');
            const eprNameFromPathname = pathNameArray[pathNameArray.length - 1];
            const taEPRLanguageFromCookie = JSON.parse(getCookie('taEprLanguage') || '{}')[
              eprNameFromPathname
            ];
            const taEprLanguage: any = {};
            taEprLanguage[eprNameFromPathname] = taEPRLanguageFromCookie || 'en';
            setCookie('taEprLanguage', JSON.stringify(taEprLanguage), {
              expires: cookie_expiry,
              secure: true,
            });
            try {
              dispatch(showLoader());
              const res = await checkForResumeUserForTALevel();
              dispatch(hideLoader());
              data = res?.data;
              if ((data && data.journeyStatus === 'partial') || data.journeyStatus === 'Partial') {
                setCookie('journeyStep', data?.journeyStep, {
                  secure: true,
                  expires: getCookieExpiryTime(
                    siteSettings?.resumeEnrollmentExpirationDays?.value ||
                      ENROLLMENT_EXPIRY_TIME_FALLBACK
                  ),
                });

                setCookie('journeyStatus', data?.journeyStatus, {
                  secure: true,
                  expires: getCookieExpiryTime(
                    siteSettings?.resumeEnrollmentExpirationDays?.value ||
                      ENROLLMENT_EXPIRY_TIME_FALLBACK
                  ),
                });

                setCookie('enrollmentID', data?.enrollmentId, {
                  secure: true,
                  expires: getCookieExpiryTime(
                    siteSettings?.resumeEnrollmentExpirationDays?.value ||
                      ENROLLMENT_EXPIRY_TIME_FALLBACK
                  ),
                });
                setCookie('journeyType', data?.journeyType, {
                  secure: true,
                  expires: getCookieExpiryTime(
                    siteSettings?.resumeEnrollmentExpirationDays?.value ||
                      ENROLLMENT_EXPIRY_TIME_FALLBACK
                  ),
                });
              }
            } catch (e) {
              dispatch(hideLoader());
            }
          }
        }
        dispatch(resetCDPData());
        let userJourneyId: any = null;
        const journeyTypeStoredInCookie = getJourneyTypeCookie();
        if (
          journeyTypeStoredInCookie !== '' &&
          journeyTypeStoredInCookie !== null &&
          journeyTypeStoredInCookie !== undefined
        ) {
          userJourneyId = journeyTypeStoredInCookie;
        } else if (siteSettings?.enableDynamicJourney?.value === true) {
          const queryParameters = { ...router.query };
          if ('site' in queryParameters) {
            delete queryParameters['site'];
          }
          const pointOfSale = sitecoreContext?.site?.name;
          if (isEmpty(queryParameters)) {
            const experienceId = siteSettings?.dynamicExperimentId?.value;
            const resumeEnrollmentExpirationDays =
              siteSettings?.resumeEnrollmentExpirationDays?.value;
            userJourneyId = await getJourneyId({
              experienceId,
              queryParameters,
              pointOfSale,
              resumeEnrollmentExpirationDays,
            });
          } else {
            const experienceId = siteSettings?.dynamicExperienceId?.value;
            const resumeEnrollmentExpirationDays =
              siteSettings?.resumeEnrollmentExpirationDays?.value;
            userJourneyId = await getJourneyId({
              experienceId,
              queryParameters,
              pointOfSale,
              resumeEnrollmentExpirationDays,
            });
          }
          if (userJourneyId === '' || userJourneyId === null || userJourneyId === undefined) {
            userJourneyId = journeyId;
          }
          setJourneyTypeCookie(userJourneyId, siteSettings?.resumeEnrollmentExpirationDays?.value);
        } else {
          setJourneyTypeCookie(journeyId, siteSettings?.resumeEnrollmentExpirationDays?.value);
          userJourneyId = getJourneyTypeCookie();
        }
        dispatch(addJourneyType(userJourneyId));
        let ipAddress = '';
        try {
          const { data: res } = await axios.get(`/api/getIp`);
          ipAddress = res?.ip || '';
        } catch (_e: any) {}
        setTimeout(() => {
          dispatch(
            updatepotentialParticipantDetails({
              potentialParticipant_ID: getCookie('bx_guest_ref') || guestId,
              ipAddress: ipAddress,
              sessionStartDateTime: '2023-06-08T05:16:31.624Z',
              sessionEndDateTime: '2023-06-08T05:16:31.624Z',
              journeyStatus: JOURNEY_STATUS.PARTIAL,
              journeyId: userJourneyId ?? journeyId,
              tenantKey,
              enrollmentId: getOrCreateEnrollmentId(
                siteSettings?.resumeEnrollmentExpirationDays?.value
              ),
              projectTherapeuticArea,
              source,
              dataControllerOrg,
              potentialId: data?.potentialId ? data?.potentialId : '',
            })
          );
        }, 300);
      }
    })();
  }, [guestId, dispatch, journeyId, tenantKey]);

  return {
    isCDPScriptLoaded: isCDPScriptLoaded.current,
    triggerStartOver: triggerStartOver,
  };
};

export default useSitecoreEngage;
