import FormControl from '@mui/material/FormControl';
import { Text } from '@sitecore-jss/sitecore-jss-nextjs';
import TextBox from './TextBox';
import { StFormLabel } from './TextBox.style';

const TextBoxWithLabel = (props: any): JSX.Element => {
  return (
    <FormControl fullWidth>
      <StFormLabel>
        <Text field={props?.label} />
      </StFormLabel>
      <TextBox
        onChangeHandler={props?.handleChange}
        placeholder={props?.placeholder}
        required={props?.required}
        requiredMessage={props?.requiredMessage}
        error={props?.errors}
        value={props?.value}
        name={props?.name}
        regexMessage={props?.regexMessage}
        regexError={props?.regexError}
        sx={{ color: (theme) => theme.palette.neutral8.main }}
      />
    </FormControl>
  );
};

export default TextBoxWithLabel;
