import Grid from '@mui/material/Grid';
import { Stack } from '@mui/system';
import {
  Field,
  ImageField,
  NextImage,
  RichTextField,
  Text,
  TextField,
  useSitecoreContext,
} from '@sitecore-jss/sitecore-jss-nextjs';
import { getCookie } from 'cookies-next';
import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/store/store';
import UpdateEmailDialog from '../../../components/RegistrationWorkflows/ThankYou/UpdateEmailDialog';
import { EMAIL_SOURCE, EMAIL_TEMPLATES, PROFILE_VERIFY_TOKEN_KEY } from '../../../constants';
import { updateRegistration } from '../../../features/registrationSlice';
import { removeTrailingSlash } from '../../../lib/utils/common';
import { RegisterMergeRichText } from '../../RegistrationWorkflows/RegistrationMergeFields';
import { UIButton } from '../UIButton.styles';
import { callDapApiSendEmail, callDapApiSendUpdateEmail, updateEmailCDP } from './common';

export interface IPayload {
  email: string;
  mailIdList: string;
  emailType: string;
  baseUrl: string;
  myProfileUrl: string;
  resendAttemptCount: number;
  siteSettingsId: string;
  language: string;
}
export interface IUpdateEmailPayload {
  existingEmail: string;
  newEmail: string;
  mailIdList: string;
  emailType: string;
  baseUrl: string;
  myProfileUrl: string;
  resendAttemptCount: number;
  siteSettingsId: string;
  language: string;
}
interface IFields {
  resendBtnText: TextField;
  changeEmailBtnText: TextField;
  image: ImageField;
  resendText: RichTextField;
  thankYouText: RichTextField;
  emailInvalidErrorMessage: Field<string>;
  emailAlreadyExistErrorMessage: Field<string>;
  cancelButtonnText: Field<string>;
  confirmButtonText: Field<string>;
  updatePopUpTitle: Field<string>;
  updatePopUpSubTitle: Field<string>;
  updatePopUpEmailLabel: Field<string>;
  updateText?: RichTextField;
}

export interface IUpdateEmailProps {
  fields?: IFields;
}

const fallbackText: Field<string> = {
  value: '',
};

const UpdateEmail: React.FC<IUpdateEmailProps> = (props) => {
  const dispatch = useDispatch();
  const resendCounterRef = useRef(0);
  const { sitecoreContext } = useSitecoreContext();
  const siteSettings: any = sitecoreContext?.siteSettings;
  const source = siteSettings?.source?.fields?.name?.value;
  const [isUpdateEmailOpen, setIsUpdateEmailOpen] = useState(false);
  const [hasEmailResend, setHasEmailResend] = useState(false);
  const [hasEmailUpdated, setHasEmailUpdated] = useState(false);
  const [disableResend, setDisableResend] = useState(false);

  const MAX_RESEND_ATTEMPTS = siteSettings?.resendMailCount?.value || 3;
  const updatePopUpEmailLabel = props.fields?.updatePopUpEmailLabel;
  const updatePopUpSubTitle = props.fields?.updatePopUpSubTitle;
  const updatePopUpTitle = props.fields?.updatePopUpTitle;
  const confirmButtonText = props.fields?.confirmButtonText;
  const cancelButtonnText = props.fields?.cancelButtonnText;
  const emailInvalidErrorMessage = props.fields?.emailInvalidErrorMessage;
  const emailAlreadyExistErrorMessage = props.fields?.emailAlreadyExistErrorMessage;
  const updateText = props?.fields?.updateText;
  const resendText = props?.fields?.resendText;
  const thankYouText = props?.fields?.thankYouText;

  let displayText = thankYouText;

  if (hasEmailUpdated) {
    displayText = updateText;
  } else if (hasEmailResend) {
    displayText = resendText;
  }

  const crEmail = useSelector((state: RootState) => state.registrationDetails.registration.email);

  const eprEmail = useSelector(
    (state: RootState) => state?.contactInfoDetails?.contactInfo?.patient?.emailAddress
  );

  let emailId = '';
  if (!!crEmail) {
    emailId = crEmail;
  } else {
    emailId = source === EMAIL_SOURCE ? crEmail || eprEmail : eprEmail;
  }

  const potentialParticipant_ID = useSelector(
    (state: RootState) => state.potentialParticipantDetails.potentialParticipant_ID
  );
  const mailIdList =
    siteSettings?.sendMailingList?.value || siteSettings?.sendMailingListId?.value || '';

  const triggerSendEmail = async (email: string) => {
    const emailType = EMAIL_TEMPLATES.REGISTRATION_WELCOME;
    const baseUrl = siteSettings?.baseUrl?.value || '';
    const cleanBaseUrl = removeTrailingSlash(baseUrl);
    const myProfileEndpoint = siteSettings?.myProfileItemUrl?.value?.href || '/manage-my-profile';
    const myProfileUrl = `${cleanBaseUrl}${myProfileEndpoint}`;

    const payload = {
      email,
      mailIdList,
      emailType,
      baseUrl,
      myProfileUrl,
      resendAttemptCount: resendCounterRef.current,
      communityPageLink: '',
      siteSettingsId: sitecoreContext?.siteSettingsId as any,
      language: sitecoreContext?.language as any,
    };
    const response = await callDapApiSendEmail(payload);
    return response;
  };

  const triggerSendUpdateEmail = async (existingEmail: string, email: string) => {
    const emailType = EMAIL_TEMPLATES.REGISTRATION_WELCOME;
    const baseUrl = siteSettings?.baseUrl?.value || '';
    const cleanBaseUrl = removeTrailingSlash(baseUrl);
    const myProfileEndpoint = siteSettings?.myProfileItemUrl?.value?.href || '/manage-my-profile';
    const myProfileUrl = `${cleanBaseUrl}${myProfileEndpoint}`;

    const payload = {
      existingEmail,
      newEmail: email,
      mailIdList,
      emailType,
      baseUrl,
      myProfileUrl,
      resendAttemptCount: resendCounterRef.current,
      communityPageLink: '',
      siteSettingsId: sitecoreContext?.siteSettingsId as any,
      language: sitecoreContext?.language as any,
    };
    const response = await callDapApiSendUpdateEmail(payload);
    return response;
  };

  const handleOnChangeEmail = () => {
    setIsUpdateEmailOpen(true);
  };

  const onEmailUpdate = async (email: string) => {
    resendCounterRef.current = 0;
    const existingEmail = emailId;
    dispatch(updateRegistration({ email }));
    const guestref = getCookie('bx_guest_ref') || potentialParticipant_ID;
    const emailUpdateRes = await updateEmailCDP(email, guestref);
    if (emailUpdateRes?.data?.success) {
      sessionStorage.setItem(PROFILE_VERIFY_TOKEN_KEY, emailUpdateRes?.data?.myProfileLinkToken);
      triggerSendUpdateEmail(existingEmail, email);
    }
    setHasEmailUpdated(true);
    setDisableResend(false);
  };

  const onResendClick = async () => {
    resendCounterRef.current = resendCounterRef.current + 1;
    if (resendCounterRef.current >= MAX_RESEND_ATTEMPTS) {
      setDisableResend(true);
    }
    await triggerSendEmail(emailId);
    setHasEmailUpdated(false);
    setHasEmailResend(true);
  };

  return (
    <>
      <UpdateEmailDialog
        open={isUpdateEmailOpen}
        setOpen={setIsUpdateEmailOpen}
        onConfirm={onEmailUpdate}
        updatePopUpEmailLabel={updatePopUpEmailLabel || fallbackText}
        updatePopUpSubTitle={updatePopUpSubTitle || fallbackText}
        updatePopUpTitle={updatePopUpTitle || fallbackText}
        confirmButtonText={confirmButtonText || fallbackText}
        cancelButtonnText={cancelButtonnText || fallbackText}
        emailInvalidErrorMessage={emailInvalidErrorMessage || fallbackText}
        emailAlreadyExistErrorMessage={emailAlreadyExistErrorMessage || fallbackText}
      />

      <Grid className="email-box">
        <Stack className="email-stack" alignItems="center" gap={1}>
          <NextImage className="email-icon" field={props.fields?.image} height={122} width={129} />
          <Grid>
            <RegisterMergeRichText className="mail-text" field={displayText ?? {}} />
          </Grid>
          <Stack alignItems="center" direction="column" gap={1} sx={{ marginTop: '40px' }}>
            <UIButton
              data-testid="reset-button-test-id"
              variant="outlined"
              sx={{
                paddingBlock: '0.5rem',
                ':disabled': {
                  border: '1px solid transparent',
                },
                height: '40px',
              }}
              disabled={disableResend}
              onClick={onResendClick}
            >
              <Text field={props?.fields?.resendBtnText} />
            </UIButton>

            <UIButton
              data-testid="change-email-button-test-id"
              variant="text"
              sx={{
                paddingBlock: '0.5rem',
              }}
              onClick={handleOnChangeEmail}
            >
              <Text field={props?.fields?.changeEmailBtnText} />
            </UIButton>
          </Stack>
        </Stack>
      </Grid>
    </>
  );
};

export default UpdateEmail;
