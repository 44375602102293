import FormLabel from '@mui/material/FormLabel';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { Theme } from '@mui/material/styles';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Text } from '@sitecore-jss/sitecore-jss-nextjs';
import { Controller } from 'react-hook-form';
import InputBase from '@mui/material/InputBase';
import InfoIcon from '@mui/icons-material/Info';

const ToolTipStyle = {
  height: '18px',
  width: '18px',
  // marginLeft: '0.2rem',
  // paddingBottom: '0.2rem',
  // alignSelf: 'center',
  color: (theme: Theme) => `${theme.palette.neutral8.main}`,
};

const FormToolTip = (props: any): JSX.Element => {
  return (
    <Tooltip title={props?.value} arrow placement="right" sx={ToolTipStyle}>
      <InfoIcon />
    </Tooltip>
  );
};
export const StInputBase = styled(InputBase)(({ theme }) => ({
  background: theme.palette.white.main,
  color: theme.palette.neutral8.main,
  borderRadius: '4px',
  padding: '0 2px 0 8px',
  marginTop: '2px',
  fontFamily: 'Proxima Nova',
  fontSize: '14px',
  lineHeight: '24px',
  fontWeight: 400,
  height: '40px',
  '&:hover': {
    border: `1px solid ${theme.palette.neutral6.main}`,
  },
  '&.Mui-focused': {
    border: `1px solid ${theme.palette.primary.main}`,
  },
  '& ::placeholder': {
    color: `${theme.palette.neutral8.main} !important`,
    fontFamily: 'Proxima Nova',
    fontSize: '13px',
    lineHeight: '16px',
  },
}));

export const StCheckBoxLabel = styled(Typography)(() => ({
  fontFamily: 'Proxima Nova',
  fontWeight: 400,
  fontSize: '16px',
  color: `#33333`,
  minWidth: '65px',
}));

export const StFormLabel = styled(FormLabel)(() => ({
  fontFamily: 'Proxima Nova',
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '22px',

  color: `#444444`,
  margin: 0,
  display: 'flex',
  alignItems: 'center',
  '&.Mui-focused': {
    color: 'none',
  },
}));

const InputCheckBox = ({ isMobileLabel, control, errors, rules }: any) => {
  const theme = useTheme();
  return (
    <Box>
      <Controller
        name={'isMobile'}
        control={control}
        rules={rules}
        render={({ field: { onChange, value, onBlur, ...rest } }) => {
          return (
            <FormControlLabel
              control={
                <Checkbox
                  checked={!!value}
                  {...rest}
                  sx={{
                    padding: '0 2px',
                    alignSelf: 'flex-start',
                    // background: '#ffffff',
                    '& .MuiSvgIcon-root': {
                      width: '20px',
                      height: '20px',
                    },
                    color: errors['isMobile'] && `${theme.palette.error.main} !important`,
                  }}
                />
              }
              onChange={onChange}
              value={value}
              onBlur={onBlur}
              label={
                <StCheckBoxLabel>
                  <Text field={isMobileLabel} />
                </StCheckBoxLabel>
              }
              sx={{
                margin: 0,
              }}
            />
          );
        }}
      />
    </Box>
  );
};

const InputLabel = ({
  name,
  label,
  toolTip,
  isMobileLabel,
  control,
  rules,
  errors,
  mobileNumberValidationMessageOnSMSConsent,
  isMobileRequired,
  showIsMobileOption,
}: any) => (
  <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
    <Box sx={{ display: 'flex', justifyContent: 'space-start' }}>
      {isMobileRequired && <span>*</span>}
      <StFormLabel focused={false} htmlFor={name}>
        <Text field={label} />
        {toolTip && <FormToolTip value={toolTip} />}
      </StFormLabel>
    </Box>
    {showIsMobileOption?.value && (
      <InputCheckBox
        isMobileLabel={isMobileLabel}
        control={control}
        errors={errors}
        isMobileRequired={isMobileRequired}
        rules={rules}
        mobileNumberValidationMessageOnSMSConsent={mobileNumberValidationMessageOnSMSConsent}
      />
    )}
  </Box>
);

const CustomInputBase = ({ name, placeholder, errors, maxLength, disabled, field }: any) => (
  <StInputBase
    name={name}
    data-testid={name}
    id={name}
    aria-describedby={placeholder}
    fullWidth
    disabled={disabled}
    placeholder={placeholder}
    {...field}
    inputProps={{
      maxLength: maxLength,
    }}
    sx={{
      border: (theme: Theme) =>
        errors && errors[name]
          ? `1px solid ${theme.palette.error.main} !important`
          : `1px solid ${theme.palette.neutral4.main}`,
      padding: '5px 8px !important',
      color: '#444444',
    }}
  />
);

const CustomMobileInput = ({
  name,
  toolTip,
  placeholder,
  label,
  onChangeHandler,
  value,
  errors,
  maxLength,
  disabled,
  onBlur,
  isMobileLabel,
  control,
  mobileNumberValidationMessageOnSMSConsent,
  field,
  isMobileRequired,
  showIsMobileOption,
  rules,
}: any) => {
  return (
    <FormControl fullWidth>
      <InputLabel
        name={name}
        label={label}
        toolTip={toolTip}
        isMobileLabel={isMobileLabel}
        control={control}
        rules={rules}
        errors={errors}
        isMobileRequired={isMobileRequired}
        showIsMobileOption={showIsMobileOption}
        mobileNumberValidationMessageOnSMSConsent={mobileNumberValidationMessageOnSMSConsent}
      />
      <CustomInputBase
        placeholder={placeholder}
        onChangeHandler={onChangeHandler}
        value={value}
        maxLength={maxLength}
        onBlur={onBlur}
        name={name}
        disabled={disabled}
        errors={errors}
        control={control}
        field={field}
      />
      {errors && errors['contactNumber'] && (
        <Typography color="error">{errors.contactNumber.message}</Typography>
      )}
      {errors && !errors['contactNumber'] && errors['isMobile'] && (
        <Typography color="error">{errors.isMobile.message}</Typography>
      )}
    </FormControl>
  );
};

export { CustomMobileInput };
