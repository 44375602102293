import React from 'react';
import CRModal from '.';
import Box from '@mui/material/Box';
import { PromptIdleTimeouFooterModal } from './FooterModal';
import { RichText } from '@sitecore-jss/sitecore-jss-nextjs';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';

interface ModalProps {
  modal: {
    open: boolean;
    onContinue: () => void;
    onSignOut: () => void;
  };
}

const PromptIdleTimeoutModal: React.FC<ModalProps> = ({
  modal: { open, onContinue, onSignOut },
}) => {
  const { sitecoreContext } = useSitecoreContext();
  const promptIdleSignouPopup = (sitecoreContext as any)?.idleSessionPopup?.promptIdleSignouPopup;
  const {
    modalCTAButtons = [],
    modalTitle,
    modalDescription,
  } = promptIdleSignouPopup?.fields || {};

  return (
    <CRModal
      open={open}
      handleClose={onContinue}
      handleCancel={onContinue}
      title={modalTitle?.value}
    >
      <Box sx={{ color: '#444444', width: '100%' }}>
        <RichText field={{ value: modalDescription?.value }} />
        <PromptIdleTimeouFooterModal
          modalCTAButtons={modalCTAButtons}
          onClose={onContinue}
          onSignOut={onSignOut}
        />
      </Box>
    </CRModal>
  );
};

export default PromptIdleTimeoutModal;
