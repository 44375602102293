import { ImageField, LinkField } from '@sitecore-jss/sitecore-jss-nextjs';
import { useEffect, useRef } from 'react';
import { StNextImage } from '../NextImage.styles';
import { StLinkSeprator, StSecondaryLinkItem, StSecondaryLinks } from './SecondaryLinks.style';

interface linkItem {
  name: string;
  link: LinkField;
  image: ImageField;
}

type IFooterLinks = Array<linkItem>;

interface IFooterPrimaryLinksProps {
  secondaryLinks: IFooterLinks;
  linkFontColor: string;
  hoverColor?: string;
}

const SecondaryLinks = (props: IFooterPrimaryLinksProps): JSX.Element => {
  const refs = useRef<any>([]);

  // To handle link seprater, on last link on each row should not any pipe seprator
  const handleLinkSeprator = () => {
    if (refs?.current) {
      const sepratorDOMListByRow: { [key: string]: Array<HTMLElement> } = {};
      for (let i = 0; i < refs.current.length; i++) {
        const height = refs.current[i]?.offsetTop;
        if (!sepratorDOMListByRow[height]) {
          sepratorDOMListByRow[height] = [];
        }
        sepratorDOMListByRow[height].push(refs.current[i]);
      }

      for (const sepratorsInArrow in sepratorDOMListByRow) {
        const selectorsInARow = sepratorDOMListByRow[sepratorsInArrow];
        const lastSelectors = selectorsInARow[selectorsInARow.length - 1];
        if (lastSelectors && lastSelectors.style) {
          lastSelectors.style.visibility = 'hidden';
        }
      }
    }
  };

  useEffect(() => {
    setTimeout(() => {
      handleLinkSeprator();
    }, 500);
  }, [refs]);

  return (
    <>
      {props?.secondaryLinks &&
        props?.secondaryLinks.map((linkItem: linkItem, index: number) => (
          <StSecondaryLinkItem key={linkItem.name} item>
            {/* <i>{linkItem.name}</i> */}
            <StSecondaryLinks
              className={'main-footer-link-btn'}
              id={linkItem.name}
              data-testid={linkItem.name}
              field={linkItem.link}
              linkFontColor={props.linkFontColor}
              hoverColor={props.hoverColor}
            />

            <span style={{ position: 'relative', top: '1px', left: '2px' }}>
              {linkItem?.image?.value?.src && (
                <StNextImage
                  suppressHydrationWarning
                  field={linkItem?.image}
                  alt={(linkItem?.image?.value?.alt as string) || ''}
                  width={(linkItem?.image?.value?.width as string) || '26'}
                  height={(linkItem?.image?.value?.height as string) || '11'}
                  overrideIconColor={props.linkFontColor}
                />
              )}
            </span>

            <StLinkSeprator
              key={index}
              ref={(element) => {
                refs.current.push(element);
              }}
            ></StLinkSeprator>
          </StSecondaryLinkItem>
        ))}
    </>
  );
};

export default SecondaryLinks;
