import { default as MuiTab } from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { styled } from '@mui/material/styles';
import * as React from 'react';
import { Text, Link, LinkField } from '@sitecore-jss/sitecore-jss-nextjs';

interface SxProps {
  [key: string]: any;
}
type TabOption = {
  value: string;
};
interface ITabsProps {
  orientation?: 'horizontal' | 'vertical';
  variant?: 'scrollable' | 'fullWidth' | 'standard';
  ScrollButtonComponent?: () => any;
  id?: string;
  value: number | string;
  onChangeHandler: (e: any, _v: number | string) => void;
  scrollButtons: 'auto' | false | true;
  ariaLabel: string;
  tabsContainerSxProps: SxProps;
  tabSxProps: SxProps;
  tabAltComponent?: any;
  tabListOptions: Array<TabOption> | Array<LinkField> | [];
  type: 'link' | 'text';
}

const StLink = styled(Link)(({ theme }) => ({
  fontFamily: 'Proxima Nova',
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: '16px',
  lineHeight: '16px !important',
  textAlign: 'center',
  letterSpacing: '0.5px',
  textTransform: 'none',
  color: theme.palette.neutral8.main,
  textDecoration: 'none',
  '&:hover': {
    color: theme.palette.neutral8.main,
    textDecoration: 'none',
  },
}));

const Tabs = (props: ITabsProps): JSX.Element => {
  const {
    value,
    onChangeHandler,
    variant,
    scrollButtons,
    ariaLabel,
    tabListOptions,
    tabsContainerSxProps,
    tabSxProps,
    orientation,
    tabAltComponent,
    type,
  } = props;

  return (
    <MuiTab
      orientation={orientation}
      value={value}
      onChange={onChangeHandler}
      variant={variant}
      scrollButtons={scrollButtons}
      aria-label={ariaLabel}
      sx={{ ...tabsContainerSxProps }}
    >
      {tabListOptions &&
        tabListOptions.map((t: any, index) => {
          return (
            <Tab
              id={t?.value}
              disableRipple
              key={index}
              component={tabAltComponent ? tabAltComponent : null}
              label={
                type === 'link' ? (
                  <div>
                    <StLink field={t} />
                  </div>
                ) : (
                  <Text field={t} />
                )
              }
              sx={{ ...tabSxProps }}
              aria-label={t?.value}
            />
          );
        })}
    </MuiTab>
  );
};

export default Tabs;
