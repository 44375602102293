import Box from '@mui/material/Box';
import { Text } from '@sitecore-jss/sitecore-jss-nextjs';
import { useRouter } from 'next/router';
import { RegisterMergeText } from '../RegistrationMergeFields';
import { useSitecoreContext, NextImage } from '@sitecore-jss/sitecore-jss-nextjs';
import { UIButton } from '../../shared/UIButton.styles';
import {
  StExploreYourProfileStack,
  StExploreYourProfileTitle,
  StExploreYourProfileWrapper,
} from './ExploreProfile.stye';

export type IIcon = {
  value: IValue;
};
export type ICtaButton = {
  value: IValue;
};
export type IValue = {
  href?: string;
  text?: string;
  anchor?: string;
  linktype?: string;
  class?: string;
  title?: string;
  target?: string;
  querystring?: string;
  id?: string;
  src?: string;
  alt?: string;
  width?: string;
  height?: string;
};
export type IHeading = {
  value: string;
};
export type IExploreProfileField = {
  ctaButton: ICtaButton;
  heading: IHeading;
  icon: IIcon;
};

interface IExploreProfile {
  fields: IExploreProfileField;
}
const ExploreProfile: React.FC<IExploreProfile> = (props) => {
  const { sitecoreContext } = useSitecoreContext();
  const siteSettings: any = sitecoreContext?.siteSettings;
  const myProfileUrl: string =
    props?.fields?.ctaButton?.value?.href || siteSettings?.myProfileItemUrl?.value?.href || '';
  const heading = props?.fields?.heading || '';
  const ctaButton = props?.fields?.ctaButton?.value?.text || '';
  const router = useRouter();

  return (
    <StExploreYourProfileWrapper>
      <Box sx={{ flex: 'none' }}>
        <NextImage field={props.fields.icon} height={122} width={93} />
      </Box>
      <Box sx={{ flex: 1 }}>
        <StExploreYourProfileStack>
          <StExploreYourProfileTitle className="subtitle" variant="subtitle1">
            <RegisterMergeText field={heading} data-testid="explore-profile-title" />
          </StExploreYourProfileTitle>

          <UIButton
            data-testid="explore-profile-button-id"
            variant="contained"
            sx={{
              paddingBlock: '0.75rem',
              paddingInline: '1.25rem',
              height: '40px',
            }}
            onClick={() => router.push(myProfileUrl)}
          >
            <Text field={{ value: ctaButton }} />
          </UIButton>
        </StExploreYourProfileStack>
      </Box>
    </StExploreYourProfileWrapper>
  );
};

export default ExploreProfile;
