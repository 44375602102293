import React from 'react';
import CRModal from '.';
import { styled } from '@mui/material/styles';
import { useDispatch } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';

import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import { StFormLabel, StProfileModalForm, StTextField } from './ProfileModal.style';
import { ProfileFooterModal, ModalCTAButton } from './FooterModal';
import SendModal from './SendModal';
import ReSendModal from './ReSendModal';
import { callSendEmailDapApi, checkUserRegisterOrNot } from '../../../services/RegisterUserService';
import { showLoader, hideLoader } from '../../../features/loaderSlice';
import SignOutModal from './SignOutModal';

interface FormValues {
  email: string;
}

interface ModalProps {
  modal: {
    open: boolean;
    setProfileModal: React.Dispatch<
      React.SetStateAction<{ open: boolean; profileFields: any; signOutOpen: boolean }>
    >;
    signOutOpen: boolean;
  };
  fieldProps: {
    myProfile?: {
      fields?: {
        isEmailRequired?: { value: boolean };
        errorFormatMsg?: { value: string };
        regex?: {
          fields?: {
            value?: { value: string };
          };
        };
        modalTitle?: { value: string };
        modalDescription?: { value: string };
        inputText?: { value: string };
        modalCTAButtons?: ModalCTAButton[];
      };
    };
    emailExistErrorMessage?: { value: string };
    emailSent?: { fields: any };
    emailResent?: { fields: any };
    resendCount?: number;
    signOut?: { fields: any };
  };
  emailType?: string;
  communityPageLink?: string;
}

export const StFooterBox = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'flex-end',
  flexGrow: '1',
  a: {
    marginRight: '25px',
    '&:last-child': {
      marginRight: '0px',
    },
  },
}));

function removeTrailingSlash(url: string) {
  if (url.endsWith('/')) {
    return url.slice(0, -1);
  }
  return url;
}

const ProfileModal: React.FC<ModalProps> = ({
  modal: { open, setProfileModal, signOutOpen },
  fieldProps,
  emailType,
  communityPageLink,
}) => {
  const dispatch = useDispatch();
  const { myProfile = {}, emailSent, emailResent, signOut } = fieldProps;
  const { sitecoreContext } = useSitecoreContext();
  const siteSettings: any = sitecoreContext?.siteSettings || {};
  const [emailSendModal, setEmailSendModal] = React.useState<boolean>(false);
  const [emailReSendModal, setEmailReSendModal] = React.useState<boolean>(false);
  const [emailResendCount, setEmailResendCount] = React.useState<number>(0);

  const { fields: myProfileFields = {} } = myProfile;
  const emailExistErrorMessage = String(
    fieldProps?.emailExistErrorMessage?.value ||
      'The email you entered does not exist. Please sign up.'
  );
  const isEmailRequired = myProfileFields?.isEmailRequired?.value || true;
  const emailRequiredErrorText = String(myProfileFields?.errorFormatMsg?.value || '');
  const emailErrorMessage = String((fieldProps as any)?.errorMessage?.value) || '';
  const emailValidationMessage = String(myProfileFields?.errorFormatMsg?.value || '');
  const emailRegex = String(
    myProfileFields?.regex?.fields?.value?.value || '/^[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,}$/i'
  );

  const emailRules = {
    required: isEmailRequired ? emailRequiredErrorText : undefined,
    pattern: {
      value: new RegExp(emailRegex, 'i'),
      message: emailValidationMessage,
    },
  };

  const sendEmail = async (email: string) => {
    const baseUrl = siteSettings?.baseUrl?.value || '';
    const cleanBaseUrl = removeTrailingSlash(baseUrl);
    const myProfileEndpoint = siteSettings?.myProfileItemUrl?.value?.href || '';
    const myProfileUrl = `${cleanBaseUrl}${myProfileEndpoint}`;

    try {
      dispatch(showLoader());
      const IsUserRegister = await checkUserRegisterOrNot(email);
      if (IsUserRegister) {
        const payload = {
          email: email,
          mailIdList: (siteSettings?.sendMailingListId?.value as string) || '',
          emailType: emailType || '',
          baseUrl: (siteSettings?.baseUrl?.value as string) || '',
          myProfileUrl: myProfileUrl,
          resendAttemptCount: emailResendCount,
          communityPageLink: communityPageLink,
          siteSettingsId: sitecoreContext?.siteSettingsId,
          language: sitecoreContext?.language,
        };
        if (emailType) {
          await callSendEmailDapApi(payload as any);
          setEmailResendCount(emailResendCount + 1);
        }
        setEmailSendModal(true);
      } else if (IsUserRegister === false) {
        setError('email', { type: 'custom', message: emailExistErrorMessage as string });
      } else {
        setError('email', { type: 'custom', message: emailErrorMessage as string });
        setEmailSendModal(false);
      }
    } catch (error) {
    } finally {
      dispatch(hideLoader());
    }
  };

  const {
    handleSubmit,
    control,
    formState: { errors, isValid },
    reset,
    trigger,
    getValues,
    setError,
  } = useForm<FormValues>();
  const formData = getValues();
  const onEmailBlur = async () => {
    await trigger('email');
  };

  const onSubmit = async (data: FormValues) => {
    if (data.email && emailType) await sendEmail(data.email);
  };

  const onClose = () => {
    setProfileModal({ open: false, profileFields: {}, signOutOpen: false });
    reset();
  };

  const onCloseSendModal = () => {
    setEmailSendModal(false);
    setEmailResendCount(0);
  };

  const onCloseReSendModal = async () => {
    setEmailReSendModal(false);
    setEmailResendCount(0);
  };

  const onCloseModals = () => {
    setEmailSendModal(false);
    setEmailReSendModal(false);
    setEmailResendCount(0);
    onClose();
  };

  const onCloseSignOutModal = () => {
    setProfileModal({ open: false, profileFields: {}, signOutOpen: false });
  };

  const onResendEmail = async (_params?: string) => {
    if (_params === 'emailSendModal') {
      if (formData.email && emailType) await sendEmail(formData.email);
      setEmailSendModal(false);
      setEmailReSendModal(true);
    }
  };
  return (
    <>
      <CRModal
        open={open}
        handleClose={onClose}
        handleCancel={onClose}
        title={myProfileFields?.modalTitle?.value}
      >
        <StProfileModalForm onSubmit={handleSubmit(onSubmit)} data-testid="myProfile-form">
          <Typography>{myProfileFields?.modalDescription?.value}</Typography>
          <FormControl fullWidth={true}>
            <StFormLabel>{myProfileFields?.inputText?.value}</StFormLabel>
            <Controller
              name="email"
              control={control}
              defaultValue=""
              rules={emailRules}
              render={({ field }) => (
                <StTextField
                  {...field}
                  placeholder={''}
                  variant="outlined"
                  fullWidth
                  error={Boolean(errors.email)}
                  helperText={errors.email?.message}
                  onBlur={onEmailBlur}
                />
              )}
            />
          </FormControl>
          <ProfileFooterModal
            modalCTAButtons={myProfileFields?.modalCTAButtons || []}
            isFormValid={isValid}
            onClose={onClose}
          />
        </StProfileModalForm>
      </CRModal>
      {emailSendModal && (
        <SendModal
          modal={{ open: emailSendModal, onClose: onCloseSendModal, onCloseModals, onResendEmail }}
          fieldProps={emailSent?.fields}
          email={formData?.email}
        />
      )}
      {emailReSendModal && (
        <ReSendModal
          modal={{
            open: emailReSendModal,
            onClose: onCloseReSendModal,
            onCloseModals,
            onResendEmail,
          }}
          fieldProps={emailResent?.fields}
          emailResendCount={emailResendCount}
          email={formData?.email}
        />
      )}
      {signOutOpen && (
        <SignOutModal
          modal={{
            open: signOutOpen,
            onClose: onCloseSignOutModal,
          }}
          fieldProps={signOut?.fields}
        />
      )}
    </>
  );
};

export default ProfileModal;
