import { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import { useTheme } from '@mui/material/styles';
import { NextImage, Text } from '@sitecore-jss/sitecore-jss-nextjs';
import moment from 'moment';
import { StCardTitle, StCardDesc, StCardSubTitle } from '../Carousel.styles';
import { ArticleCardType } from '../Carousel.types';
import { useRouter } from 'next/router';
import { useDispatch } from 'react-redux';
import { updateLastVisitedComponentId } from '../../../../features/globalConfig';

const ArticleCard = (props: ArticleCardType): JSX.Element => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const router = useRouter();
  const cardData = props?.data?.fields;
  const [publishDate, setPublishDate] = useState<any>(cardData?.articlePublishDate);

  useEffect(() => {
    const publishDate = {
      value: moment(cardData?.articlePublishDate.value).format('DD MMMM yyyy'),
    };
    setPublishDate(publishDate);
  }, []);

  const getCardWidth = () => {
    if (props?.isTablet) {
      return 337;
    } else if (props?.isMobile) {
      return 292;
    } else {
      return 262;
    }
  };

  const getCardMaxWidth = () => {
    if (props?.isTablet) {
      return 337;
    } else if (props?.isMobile) {
      return 292;
    } else {
      return 355;
    }
  };

  const onCardClickHandler = (url: string) => {
    dispatch(updateLastVisitedComponentId('article-carousel'));
    router.push(url);
  };

  return (
    <Card
      sx={{
        minWidth: getCardWidth(),
        maxWidth: getCardMaxWidth(),
        marginInline: props?.isTablet || props?.isMobile ? 'none' : 'auto',
        margin: props?.isTablet || props?.isMobile ? '1.4rem' : 'none',
        borderRadius: '0.6rem',
        boxShadow: `${theme.palette.neutral4.main} 0px 4px 12px`,
        position: 'relative',
        cursor: 'pointer',
      }}
      onClick={() => onCardClickHandler(props?.data?.url)}
    >
      <Box sx={{ display: 'flex', justifyContent: 'center', height: '232px', padding: '1rem' }}>
        <NextImage field={cardData?.articleImage} />
      </Box>

      <CardContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          padding: '0 1rem 1rem 1rem',
        }}
      >
        <StCardTitle
          sx={{
            marginBottom: '1rem',
          }}
        >
          <Text field={cardData?.articleConditions?.fields?.description} />
        </StCardTitle>
        <StCardSubTitle
          limit={props?.titleLineLimit}
          sx={{
            marginBottom: '0.5rem',
          }}
        >
          <Text field={cardData?.articleTitle} />
        </StCardSubTitle>
        <StCardDesc
          limit={props?.descriptionLineLimit}
          sx={{
            marginBottom: '1rem',
          }}
        >
          <Text field={cardData?.articleDescription} />
        </StCardDesc>
        <StCardTitle>
          <Text field={publishDate} />
        </StCardTitle>
      </CardContent>
    </Card>
  );
};

export default ArticleCard;
