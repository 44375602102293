/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable @next/next/no-img-element */
import * as React from 'react';
import { ComponentProps } from 'lib/component-props';
import { Field, ImageField, Text } from '@sitecore-jss/sitecore-jss-nextjs';
import {
  StBannerContainer,
  StHcpHeadingBox,
  StHcpHeadingWrapper,
  StHcpImageWrapper,
  StHcpMainContainer,
  StHcpOverlayHeadingWrapper,
  StHcpOverlayImageWrapper,
  StHcpSubHeadingBox,
  StOverlayBannerContainer,
  StSubBannerContainer,
} from './HcpSubPageBanner.style';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

export type IHcpSubPageBannerProps = ComponentProps & {
  fields: {
    hcpBannerDesktop: ImageField;
    hcpBannerMob: ImageField;
    hcpBannerTab: ImageField;
    hcpHeading?: Field<string>;
    hcpSubHeading?: Field<string>;
    textOverlapInMob: Field<boolean>;
  };
};
const HcpSubPageBanner = (props: IHcpSubPageBannerProps): JSX.Element => {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('lg'));
  const isMobile = useMediaQuery(theme.breakpoints.down(620));

  const fields = props?.fields || {};
  const mobileImage = fields?.hcpBannerMob?.value?.src;
  const tabletImage = fields?.hcpBannerTab?.value?.src;
  const desktopImage = fields?.hcpBannerDesktop?.value?.src;
  const isTextOverlapInMob = fields?.textOverlapInMob?.value;
  const imageUrl = isMobile ? mobileImage : isTablet ? tabletImage : desktopImage;
  if (!props?.fields) return <></>;

  return (
    <StHcpMainContainer>
      {!isTextOverlapInMob ? (
        <StBannerContainer id={'hcp-sub-banner'}>
          <StHcpImageWrapper>
            <img src={imageUrl} style={{ borderRadius: '16px 16px 0 0' }} />
          </StHcpImageWrapper>
        </StBannerContainer>
      ) : (
        <StOverlayBannerContainer id={'hcp-sub-banner'}>
          <StHcpOverlayImageWrapper>
            <img src={imageUrl} style={{ borderRadius: '16px 16px 0 0' }} />
          </StHcpOverlayImageWrapper>
        </StOverlayBannerContainer>
      )}
      {!isTextOverlapInMob ? (
        <StSubBannerContainer>
          <StHcpHeadingWrapper>
            <StHcpHeadingBox isMobile={isMobile} isTextOverlapInMob={isTextOverlapInMob}>
              <Text data-testid={props?.fields?.hcpHeading} field={props?.fields?.hcpHeading} />
            </StHcpHeadingBox>
            {props?.fields?.hcpSubHeading && (
              <StHcpSubHeadingBox isMobile={isMobile} isTextOverlapInMob={isTextOverlapInMob}>
                <Text
                  data-testid={props?.fields?.hcpSubHeading}
                  field={props?.fields?.hcpSubHeading}
                />
              </StHcpSubHeadingBox>
            )}
          </StHcpHeadingWrapper>
        </StSubBannerContainer>
      ) : (
        <StSubBannerContainer>
          <StHcpOverlayHeadingWrapper>
            <StHcpHeadingBox isMobile={isMobile} isTextOverlapInMob={isTextOverlapInMob}>
              <Text data-testid={props?.fields?.hcpHeading} field={props?.fields?.hcpHeading} />
            </StHcpHeadingBox>
            {props?.fields?.hcpSubHeading && (
              <StHcpSubHeadingBox isMobile={isMobile} isTextOverlapInMob={isTextOverlapInMob}>
                <Text
                  data-testid={props?.fields?.hcpSubHeading}
                  field={props?.fields?.hcpSubHeading}
                />
              </StHcpSubHeadingBox>
            )}
          </StHcpOverlayHeadingWrapper>
        </StSubBannerContainer>
      )}
    </StHcpMainContainer>
  );
};

export default HcpSubPageBanner;
