import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { Text } from '@sitecore-jss/sitecore-jss-nextjs';
import CheckboxWithLabel from './CheckboxWithLabel';
import { StFormLabel } from './CheckboxWithLabel.styles';

const CheckboxWithLabelContainer = (props: any): JSX.Element => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
      }}
    >
      <StFormLabel sx={{ paddingBottom: '10px' }}>
        <Text field={props?.label} />
      </StFormLabel>
      <Grid container direction="column" gap="8px">
        {props?.options.map((item: any, itemIndex: number) => {
          return (
            <Grid item xs={12} key={itemIndex}>
              <CheckboxWithLabel
                index={itemIndex}
                type={props?.type}
                label={item.fields?.name}
                handleChange={props?.handleChange}
                name={item?.fields?.name}
                checked={
                  props?.storedAnswers.find((ans: any) => ans.index === itemIndex) !== undefined
                    ? true
                    : false
                }
              />
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

export default CheckboxWithLabelContainer;
