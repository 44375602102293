import { SxProps } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { Text, Field, ImageField, NextImage } from '@sitecore-jss/sitecore-jss-nextjs';
import { StNextImage } from '../../shared/NextImage.styles';

interface CommunityItem {
  url: string;
  name: string;
  fields: {
    title: Field<string>;
    relatedCommunity: {
      fields: { icon: ImageField };
    };
  };
}

type CommunityCardProps = {
  communityItem: CommunityItem;
  communityCardColor?: string;
  isMobile: boolean;
  onClickHandler: (element: CommunityItem) => void;
  onKeyUp?: (e: any, element: CommunityItem) => void;
  sxCommunityCardProps: SxProps;
  sxCommunityCardTitleProps: SxProps;
};

const CommunityCard = (props: CommunityCardProps): JSX.Element => {
  const {
    communityItem,
    communityCardColor,
    isMobile,
    onClickHandler,
    sxCommunityCardProps,
    sxCommunityCardTitleProps,
    onKeyUp = () => void 0,
  } = props;
  return (
    <Grid
      item
      xs={12}
      sm={'auto'}
      id={`${communityItem?.name}`}
      data-testid="community-card"
      role="link"
      onClick={() => onClickHandler(communityItem)}
      onKeyUp={(e: any) => {
        onKeyUp(e, communityItem);
      }}
      sx={{ marginBottom: '1rem', marginLeft: isMobile ? '0' : '1rem' }}
      tabIndex={0}
    >
      <Grid item container direction={isMobile ? 'row' : 'column'} sx={sxCommunityCardProps}>
        {communityCardColor && communityItem?.fields?.relatedCommunity?.fields?.icon?.value?.src ? (
          <StNextImage
            suppressHydrationWarning
            field={communityItem?.fields?.relatedCommunity?.fields?.icon}
            height={isMobile ? '24' : '59'}
            width={
              isMobile
                ? '24'
                : (communityItem?.fields?.relatedCommunity.fields.icon?.value?.width as string)
            }
            overrideIconColor={communityCardColor as string}
          />
        ) : (
          communityItem?.fields?.relatedCommunity?.fields?.icon?.value?.src && (
            <div>
              <NextImage
                field={communityItem?.fields?.relatedCommunity?.fields?.icon}
                height={isMobile ? '24' : '59'}
                width={
                  isMobile
                    ? '24'
                    : (communityItem?.fields?.relatedCommunity?.fields.icon?.value?.width as string)
                }
              />
            </div>
          )
        )}

        <Typography variant="body" sx={sxCommunityCardTitleProps}>
          <Text data-testid={communityItem?.fields?.title} field={communityItem?.fields?.title} />
        </Typography>
      </Grid>
    </Grid>
  );
};

export default CommunityCard;
