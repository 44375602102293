import React, { useEffect, useMemo, useState } from 'react';
import { RegWorkflowContainer } from '../RegistrationWorkflow.style';
import RegWorkflowFactory from '../RegWorkflowFactory';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import { Controller, useForm } from 'react-hook-form';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import { setDOBAndGender } from '../../../features/registrationSlice';
import { getQuery } from '../../../lib/utils/common';
import moment from 'moment';

const RegistrationDOB = (props: any) => {
  const dispatch = useDispatch();
  const storedAnswers = useSelector((state: RootState) => state.registrationDetails.dob);
  const [enableNext, setEnableNext] = useState<boolean>(false);

  const getFormDataDefaultValue = (existingValues: any) => {
    return {
      Day: existingValues?.Day ?? '',
      Month: existingValues?.Month ?? '',
      Year: existingValues?.Year ?? '',
      dob: existingValues?.dob ?? '',
      gender: existingValues?.gender ?? '',
    };
  };
  const theme = useTheme();
  const router = useRouter();
  const { query } = router;

  const {
    control,
    setValue,
    setError,
    clearErrors,
    formState: { errors, isValid, isDirty },
    trigger,
    watch,
  } = useForm({
    mode: 'all',
    defaultValues: getFormDataDefaultValue(storedAnswers),
  });

  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const genderList = props?.fields?.genderList;
  const genderLabel = props?.fields?.genderLabel;
  const startYear = props?.fields?.startyear?.value || 1900;
  const dateOfBirthFormat = props?.fields?.dateOfBirthFormat;
  const maxAge = props?.fields?.maxAge || 100;
  const minAge = props?.fields?.minAge || 18;
  const isDateofBirthRequired = props?.fields?.isDateofBirthRequired;
  const isDateofBirthAgeCheck = props?.fields?.isDateofBirthAgeCheck;
  const genderRequiredMessage = props?.fields?.genderRequiredMessage;
  const dateofBirthAgeCheckErrorMessage = props?.fields?.dateofBirthAgeCheckErrorMessage;
  const dateOfBirthRequiredMessage = props?.fields?.dateOfBirthRequiredMessage;
  const dateOfBirthInvalidMessage = props?.fields?.dateOfBirthInvalidMessage;
  const isGenderRequired = props.fields?.isGenderRequired;
  const formData = watch();
  const yearsArray = useMemo(() => {
    const endYear = new Date().getFullYear();
    const yearList: any = [];
    for (let year = startYear; year <= endYear; year++) {
      yearList.push(year);
    }
    return yearList;
  }, []);

  useEffect(() => {
    if (
      (props?.fields?.isGenderRequired.value && formData.gender === '') ||
      (props?.fields?.isDateofBirthRequired.value &&
        (formData.Day === '' || formData.Month === '' || formData.Year === '' || errors?.dob))
    ) {
      setEnableNext(false);
    } else {
      setEnableNext(true);
    }
  }, [formData]);

  const handleClick = async (event: any, url: any, action: any) => {
    event.preventDefault();
    dispatch(setDOBAndGender(formData));
    if (action === 'back') {
      if (url !== '') {
        router.push(
          {
            pathname: url,
            query: getQuery(query),
          },
          url
        );
      } else {
        history.back();
      }
    } else {
      await trigger();
      if (isValid) {
        router.push(
          {
            pathname: url,
            query: getQuery(query),
          },
          url
        );
      }
    }
  };
  const daysArray = Array.from({ length: 31 }, (_, index) => index + 1);

  const getFieldByKey = (fields: any, keyName: string) => {
    return fields.find((item: any) => item.fields.key?.value === keyName);
  };

  const dayField = getFieldByKey(dateOfBirthFormat, 'Day');
  const monthField = getFieldByKey(dateOfBirthFormat, 'Month');
  const yearField = getFieldByKey(dateOfBirthFormat, 'Year');

  const validateAge = (_: any, values: any) => {
    const { Day, Month, Year } = values;
    const selectedDate = new Date(values.Year, values.Month - 1, values.Day);
    const age = moment().diff(selectedDate, 'years', true);

    if (Day && Month && Year) {
      const formattedDate = `${Year}-${Month.toString().padStart(2, '0')}-${Day.toString().padStart(
        2,
        '0'
      )}`;
      setValue('dob', formattedDate);
      const isValidDate = moment(formattedDate, 'YYYY-MM-DD', true).isValid();
      if (isValidDate) {
        clearErrors('dob');
      } else {
        setError('dob', {
          type: 'manual',
          message:
            dateOfBirthInvalidMessage?.value || 'Please provide a valid day/month combination.',
        });
        return false;
      }
    }
    if (isDirty && isDateofBirthRequired.value && !(!!Day && !!Month && !!Year)) {
      setError('dob', { type: 'manual', message: dateOfBirthRequiredMessage?.value });
      return false;
    } else if (isDateofBirthAgeCheck.value && !!Day && !!Month && !!Year) {
      if (age > parseInt(maxAge?.value) || age < parseInt(minAge?.value)) {
        setError('dob', { type: 'manual', message: dateofBirthAgeCheckErrorMessage?.value });
        return false;
      } else {
        clearErrors('dob');
      }
    }

    return true;
  };
  const validateGender = async (value: string) => {
    if (value === '' && isGenderRequired?.value) {
      return genderRequiredMessage?.value;
    }
    return true;
  };

  const isDOBRequired =
    isDateofBirthRequired?.value &&
    (formData?.Day !== '' && formData?.Month !== '' && formData?.Year !== '') === false;

  return (
    <RegWorkflowContainer>
      <RegWorkflowFactory
        title={props?.fields?.title}
        currentPage={2}
        totalCount={6}
        buttonsItems={props?.fields?.ctaLinks}
        handleClick={handleClick}
        enableNext={enableNext}
      >
        <form>
          <Grid container>
            <Controller
              name="dob"
              control={control}
              rules={{
                required: isDOBRequired ? dateOfBirthRequiredMessage?.value : false,
                validate: validateAge,
              }}
              render={() => (
                <>
                  <Grid item xs={12}>
                    <InputLabel>{isDateofBirthRequired && <span>*</span>}Date of Birth</InputLabel>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <Controller
                        name={dayField?.fields?.key?.value}
                        control={control}
                        rules={{
                          validate: validateAge,
                        }}
                        render={({ field }) => (
                          <Select
                            displayEmpty
                            style={{ height: '40px' }}
                            inputProps={{
                              'aria-label': dayField?.fields?.label?.value,
                            }}
                            fullWidth
                            {...field}
                            sx={{
                              '.MuiSelect-select': {
                                color: theme.palette.neutral8.main,
                              },
                            }}
                          >
                            <MenuItem
                              value=""
                              sx={{
                                color: theme.palette.neutral8.main,
                              }}
                            >
                              {dayField?.fields?.placeholderLabel?.value}
                            </MenuItem>
                            {daysArray.map((day) => (
                              <MenuItem
                                key={day}
                                value={day}
                                sx={{
                                  color: theme.palette.neutral8.main,
                                }}
                              >
                                {day}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                    </Grid>

                    <Grid item xs={4}>
                      <Controller
                        name={monthField?.fields?.key?.value}
                        control={control}
                        rules={{
                          validate: validateAge,
                        }}
                        render={({ field }) => (
                          <Select
                            style={{ height: '40px' }}
                            displayEmpty
                            inputProps={{ 'aria-label': monthField?.fields?.label?.value }}
                            fullWidth
                            {...field}
                            sx={{
                              '.MuiSelect-select': {
                                color: theme.palette.neutral8.main,
                              },
                            }}
                          >
                            <MenuItem
                              value=""
                              sx={{
                                color: theme.palette.neutral8.main,
                              }}
                            >
                              {monthField?.fields?.placeholderLabel?.value}
                            </MenuItem>
                            {monthField.fields.monthList.map((month: any, index: any) => (
                              <MenuItem
                                key={month?.fields?.value?.value}
                                value={index + 1}
                                sx={{
                                  color: theme.palette.neutral8.main,
                                }}
                              >
                                {month.displayName}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                    </Grid>

                    <Grid item xs={4}>
                      <Controller
                        name={yearField?.fields?.key?.value}
                        control={control}
                        rules={{
                          validate: validateAge,
                        }}
                        render={({ field }) => (
                          <Select
                            style={{ height: '40px' }}
                            displayEmpty
                            inputProps={{ 'aria-label': yearField?.fields?.label?.value }}
                            fullWidth
                            {...field}
                            sx={{
                              '.MuiSelect-select': {
                                color: theme.palette.neutral8.main,
                              },
                            }}
                          >
                            <MenuItem
                              value=""
                              sx={{
                                color: theme.palette.neutral8.main,
                              }}
                            >
                              {yearField?.fields?.placeholderLabel?.value}
                            </MenuItem>
                            {yearsArray.map((year) => (
                              <MenuItem
                                key={year}
                                value={year}
                                sx={{
                                  color: theme.palette.neutral8.main,
                                }}
                              >
                                {year}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                    </Grid>
                  </Grid>
                  <Grid container>
                    {errors.dob && (
                      <Typography color="error">{`${errors?.dob?.message}`}</Typography>
                    )}
                  </Grid>
                </>
              )}
            />
          </Grid>
          <br></br>
          <Grid container>
            <Controller
              control={control}
              rules={{
                validate: validateGender,
              }}
              render={({ field }) => (
                <FormControl>
                  <FormLabel focused={false} id="gender-label">
                    {isGenderRequired && <span>*</span>}
                    {genderLabel?.value}
                  </FormLabel>
                  <RadioGroup row={isDesktop} aria-labelledby="gender-group" {...field}>
                    {genderList.map((item: any) => (
                      <FormControlLabel
                        key={item?.fields?.id}
                        value={item?.fields?.value?.value}
                        control={<Radio size="small" />}
                        label={item?.fields?.label?.value}
                        sx={{
                          color: theme.palette.neutral8.main,
                        }}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              )}
              name="gender"
            />
            {errors.gender && <Typography color="error">{`${errors.gender.message}`}</Typography>}
          </Grid>
        </form>
      </RegWorkflowFactory>
    </RegWorkflowContainer>
  );
};

export default RegistrationDOB;
